// Image Carousel
.dt-image-carousel {
  position: relative;

  &:hover {
    cursor: -webkit-grab; }

  .swiper-slide {
    position: relative;

    > img {
      width: 100%;
      height: auto;
      margin: 0; } }

  > [data-swiper="pagination"] {
    position: absolute;
    width: 100%;
    height: 55px;
    background-color: rgba(255,255,255,0.25);
    bottom: 0;
    z-index: 50;
    text-align: center;
    padding: 13px 0;

    > span {
      display: inline-block;
      width: 10px;
      height: 10px;
      border: 1px solid #000;
      background-color: transparent;
      opacity: 1;
      transition: all 0.15s ease-in-out;

      &:hover,
      &[class*="active"] {
        background-color: #000;
        border-color: transparent; } } }

  > [data-swiper="prev"],
  > [data-swiper="next"] {
    position: absolute;
    color: #FFF;
    top: 50%;
    z-index: 50;
    width: 28px;
    height: 28px;
    background-color: #000;
    transform: translateY(-50%);
    transition: all 0.15s ease-in-out;


    &:hover {
      cursor: pointer;
      color: #000;
      background-color: #FFF; }

    &.swiper-button-disabled {
      cursor: not-allowed; }

    > i {
      position: absolute;
      font-size: 24px;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%); } }

  > [data-swiper="next"] {
    right: 0; } }


// Post Carousel
.dt-item-carousel {
  background-color: #000;
  padding: 0 320px;
  position: relative;
  @media (max-width: 1024px) {
    padding: 0 170px; }
  @media (max-width: 768px) {
    padding: 0 150px; }

  @media (max-width: 480px) {
    padding: 0 0px; }

  &:hover {
    cursor: -webkit-grab; }

  .swiper-slide {
    .dt-item {
      display: block;
      position: relative;

      > img {
        width: 100%;
        height: auto;
        margin: 0; }

      > .dt-caption {
        position: absolute;
        color: #FFF;
        width: 100%;
        bottom: 30px;
        padding: 0 35px;
        transform: translateY(100%);
        transition: all 0.5s cubic-bezier(0.18, 0.89, 0.32, 1.28);
        opacity: 0;

        .dt-title {
          background-color: rgba(0,0,0,0.7);
          padding: 25px;
          margin: 0;
          color: #FFF;
          font-weight: normal; } }

      &:hover {
        .dt-caption {
          opacity: 1;
          transform: translateY(0%); } } } }

  [data-swiper="prev"],
  [data-swiper="next"] {
    position: absolute;
    top: 50%;
    height: 100%;
    width: 18%;
    z-index: 50;
    transform: translateY(-50%);
    transition: all 0.15s ease-in-out;

    > i {
      position: absolute;
      top: 50%;
      left: 50%;
      font-size: 60px;
      transform: translate(-50%, -50%);
      color: #FFF;
      transition: all 0.35s cubic-bezier(0.18, 0.89, 0.32, 1.28); }

    // If Has Title
    &.dt-with-title {
      > .dt-title {
        margin: 0;
        font-weight: bold;
        text-align: center;
        color: #FFF;
        position: absolute;
        width: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: 1;
        transition: all 0.35s cubic-bezier(0.18, 0.89, 0.32, 1.28);
        @media (max-width: 768px) {
          font-size: 1em; }
        @media (max-width: 480px) {
            display: none; } }

      > i {
        transform: translate(-50%, -200%);
        opacity: 0;
        @media (max-width: 480px) {
          opacity: 1;
          transform: translate(-50%, -50%); } }

      &:hover {
        > i {
          transform: translate(-50%, -50%);
          opacity: 1; }
        > .dt-title {
          transform: translate(-50%, 250%);
          opacity: 0; } } }

    &:hover {
      cursor: pointer;
      > i {
        opacity: 0.5; } } }

  [data-swiper="prev"] {
    left: 0; }

  [data-swiper="next"] {
    right: 0; }

  &.dt-style-02 {
    background-color: transparent;
    position: relative;

    &:before {
      content: '';
      background-color: #000;
      position: absolute;
      height: 177px;
      width: 100%;
      top: 25px;
      left: 0;
      @media (max-width: 1024px) {
        height: 167px; }
      @media (max-width: 768px) {
        top: 0px;
        height: 100%; } }

    [data-swiper="prev"],
    [data-swiper="next"] {
      height: 75%;
      top: 50%;
      transform: translateY(-50%); }

    > .swiper-container {
      @media (min-width: 769px) {
        padding: 25px 0; } }

    .swiper-slide {
      transition: all 0.5s cubic-bezier(0.18, 0.89, 0.32, 1.28);

      .dt-caption {
        padding: 0 15px;
        bottom: 15px;

        .dt-title {
          padding: 20px; } }

      &.swiper-slide-active {
        @media (min-width: 769px) {
          transform: scale(1.2); }
        .dt-caption {
          transition-delay: 0.4s;
          opacity: 1;
          transform: translateY(0%);

          .dt-title {
            padding: 15px;
            font-size: 1.2em; } } } }

    &.gray {
      &:before {
        background-color: #ebeef3; }

      [data-swiper="prev"],
      [data-swiper="next"] {
        > i {
          color: #000; }
        &.dt-with-title > .dt-title {
          color: #000;
          @media (max-width: 480px) {
            display: none; } } }

      .dt-caption > .dt-title {
        border: 1px solid #000;
        background-color: rgba(255,255,255, 0.9);
        color: #000; } } }

  &.dt-style-03 {
    padding: 0 10%;

    [data-swiper="prev"],
    [data-swiper="next"] {
      width: 10%;

      > i {
        font-size: 40px; } } }

  [data-click-to-slide="true"] .swiper-slide:hover {
    cursor: pointer; } }


// Article Carousel
.dt-posts-carousel {
  position: relative;

  .swiper-slide {
    padding-right: 120px;
    padding-left: 120px;

    @media (max-width: 480px) {
      padding: 25px; } }

  [data-swiper="pagination"] {
    text-align: center;
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    z-index: 100;

    > span {
      width: 20px;
      height: 20px;
      border: 1px solid #000;
      opacity: 1;
      background-color: transparent;
      margin: 0 22px;
      transition: all 0.15s ease-in-out;

      &[class*="active"] {
        background-color: #000; } } }

  [data-swiper="next"],
  [data-swiper="prev"] {
    position: absolute;
    top: 30%;
    font-size: 60px;
    color: #000;
    z-index: 100;

    &:hover {
      cursor: pointer; } }

  [data-swiper="prev"] {
    left: 0; }

  [data-swiper="next"] {
    right: 0; } }
