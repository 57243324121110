.dt-blog-entries {
  @include clearfix();
  @media (min-width: 1024px) {
    &[data-column="2"] {
      .dt-post {
        display: inline-block;
        width: 50%;
        padding: 0 18px;

        &:nth-child(odd) {
          float: left;
          clear: left; }
        &:nth-child(even) {
          clear: right; } } } }

  &.dt-full {
    > .dt-post {
      padding-right: 115px;
      padding-bottom: 100px;
      padding-left: 115px;
      border-bottom: 1px solid #d0d4dd;

      .dt-entry-featured {
        margin-right: -115px;
        margin-left: -115px; }

      &:last-child {
        padding-bottom: 0;
        border-bottom: 0; }

      @media (max-width: 768px) {
        padding-right: 30px;
        padding-left: 30px;
        padding-bottom: 30px;

        .dt-entry-featured {
          margin-right: -30px;
          margin-left: -30px; } } } } }

// Feauted Post
.dt-featured-post {
  margin-bottom: 100px;

  > .dt-post {
    margin-bottom: 0;
    padding-bottom: 100px;
    border-bottom: 1px solid #d0d4dd; } }

