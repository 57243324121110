.dt-ad-placed {
  > a {
    display: block;
    text-align: center;
    > img {
      margin: 0 auto; } }

  // Back Background
  &.black-bg {
    background-color: #000; }

  &.gray-bg {
    background-color: #e5e5e5; } }
