body {
  @include font-smoothing; // <- _mixins.scss
  color: $primary;
  font-family: 'Josefin Sans', sans-serif;
  font-size: 18px;
  line-height: 30px;

  // Add margins
  @media (min-width: 1025px) {
    padding-right: 80px;
    padding-left: 80px; }


  &.no-margin {
    margin-right: 0;
    margin-left: 0; }

  &.no-padding {
    padding-right: 0;
    padding-left: 0; } }

// Links
a {
  color: $accent;
  text-decoration: none;
  transition: all 0.15s ease-in-out;

  &.dt-become-author {
    font-size: 1.333333333333333em;
    padding: 20px 0; } }

// Sections
.section-padding {
  padding-top: 150px; }

// Angle Icon
.dt-section-switch {
  display: block;
  position: relative;
  z-index: 100;

  > .dt-angle-shape {
    display: block;
    width: 60px;
    height: 35px;
    background-image: url(../img/arrow.png);
    background-repeat: no-repeat;
    margin: 0 auto;

    &.alt {
      background-image: url(../img/arrow-alt.png); } } }

// Headings
h1,h2,h3,h4,h5,h6,
.h1,.h2,.h3,.h4,.h5,.h6 {
  font-weight: bold; }

.dt-title {
  text-transform: uppercase;

  > small {
    font-weight: bold; }

  &.black {
    color: #000; } }

hr {
  border-top-color: #cccccc;
  &.dt-line {
    border-top-color: #000; }
  &.dt-ext-mb {
    margin-bottom: 50px; }
  &.dt-extra-padding {
    margin-top: 90px;
    margin-bottom: 100px; }
  &.sep {
    margin-bottom: 50px;
    margin-top: 50px; } }

.dt-tb-line {
  border-top: 1px solid #000;
  border-bottom: 1px solid #000; }

// Text Style
.text-underline {
  text-decoration: underline; }
