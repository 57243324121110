.dt-categories-list {
  padding-left: 0;
  margin-bottom: 0;

  > li {
    list-style: none;
    margin-bottom: 20px;
    > a {
      padding-left: 15px;
      display: block; } } }
