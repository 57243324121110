.dt-page-header {
  padding: 130px 0;
  background-position: center center;
  background-size: cover;
  overflow: hidden;

  @media (max-width: 768px) {
    padding: 30px 0; }

  .dt-title {
    margin: 0;
    background-color: rgba(0,0,0,0.70);
    color: #FFF;
    padding: 45px;
    font-weight: normal;
    font-size: 3.333333333333333em;
    line-height: 1em;
    position: relative;
    @media (max-width: 768px) {
      padding: 20px;
      font-size: 2em; }

    &:before,
    &:after, {
      content: '';
      position: absolute;
      height: 100%;
      width: 500%;
      background-color: rgba(0,0,0,0.70);
      top: 0; }

    &:before {
      left: -500%; }

    &:after {
      right: -500%; } }

  // Right Title
  &.dt-right-text {
    .dt-title {
      padding: 45px 0px 45px 100px;
      float: right;
      @media (max-width: 768px) {
        padding: 20px 0px 20px 50px; }

      &:before {
        display: none; } } }

  &.dt-alt-color {
    .dt-title {
      background-color: rgba(57,86,135, 0.10);
      color: #000;

      &:before,
      &:after {
        background-color: rgba(57,86,135, 0.10); } } } }
