.widget {
  .dt-reviews-list {
    > .dt-item {
      position: relative;
      padding-bottom: 15px;
      margin-bottom: 35px;
      border-bottom: 1px solid #cccccc;

      &:last-child {
        margin-bottom: 0;
        border-bottom: none; }

      > .dt-thumb {
        width: 96px;
        height: 128px;
        overflow: hidden;
        float: left;

        > img {
          width: 100%;
          height: auto; } }

      > .dt-content {
        padding-left: 116px;

        > .dt-title {
          color: #000; }
        > .dt-ratting {
          color: #7e7e7e; } } } } }
