#dt-menu-trigger {
  width: 40px;
  height: 30px;
  position: relative;
  transform: rotate(0deg);
  transition: all 0.5s ease-in-out;
  cursor: pointer;

  > span {
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    background: #000;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: all 0.25s ease-in-out;

    &:nth-child(1) {
      top: 0px; }

    &:nth-child(2),
    &:nth-child(3) {
      top: 12px; }

    &:nth-child(4) {
      top: 24px; } }

  &.open {
    > span {
      &:nth-child(1) {
        top: 10px;
        width: 0%;
        left: 50%; }
      &:nth-child(2) {
        transform: rotate(45deg); }

      &:nth-child(3) {
        transform: rotate(-45deg); }

      &:nth-child(4) {
        top: 10px;
        width: 0%;
        left: 50%; } } } }
