.dt-contact-form {
  label {
    font-weight: normal;
    color: #000;
    margin-bottom: 0; }

  p {
    margin-bottom: 25px; }

  input,
  textarea {
    display: block;
    width: 100%;
    border: 1px solid #000;
    border-top: none;
    padding: 0px 10px;

    &:focus,
    &:active {
      outline-color: transparent; }

    &[type="submit"] {
      background-color: #000;
      color: #FFF;
      padding-top: 7px;
      padding-bottom: 7px;
      transition: all 0.15s ease-in-out;

      &:hover {
        background-color: transparent;
        color: #000; } } } }
