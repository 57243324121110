.widget {
  .dt-latest-photos {
    margin-right: -10px;
    margin-left: -10px;

    > .photo-items {
      position: relative;

      > .dt-gallery-item {
        display: block;
        width: auto;
        height: 90px;
        overflow: hidden;
        margin: 10px;

        > a {
          display: block;
          height: 100%;

          > img {
            height: 100%;
            width: auto; } } } } } }
