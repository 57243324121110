.dt-section-heading {
  .dt-section-title,
  .dt-section-title-style-2 {
    margin-bottom: 90px; }

  .dt-section-title-style-3 {
    margin-bottom: 60px; }

  .dt-section-description {
    margin-bottom: 90px; } }
