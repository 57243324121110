.dt-pagination-container {
  border-top: 1px solid #cccccc;
  border-bottom: 1px solid #cccccc;
  padding: 20px 0; }

.dt-pagination {
  padding-left: 0;
  margin-bottom: 0;

  > li {
    display: inline-block;
    line-height: 1em;
    margin: 0 8px;
    border: 1px solid #000;
    transition: all 0.15s ease-in-out;

    &:first-child {
      margin-left: 0; }

    > a {
      display: block;
      padding: 5px 10px;
      color: #000;
      position: relative;
      border-bottom: 1px solid transparent;

      &:before,
      &:after {
        content: '';
        position: absolute;
        width: 1px;
        height: 100%;
        bottom: 0;
        background-color: transparent;
        transition: all 0.15s ease-in-out;
        transition-delay: 0.15s; }

      &:before {
        left: 0px; }

      &:after {
        right: 0px; } }


    &:hover,
    &.active {
      border-color: transparent;
      > a {
        border-bottom-color: #000;
        &:before,
        &:after {
          height: 50%;
          background-color: #000; } } } } }
