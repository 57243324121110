.dt-contacts-list {
  padding: 0;

  > li {
    list-style: none;
    color: #000;

    margin-bottom: 10px;

    > a {
      text-decoration: underline; } } }
