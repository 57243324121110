.widget {
  .dt-recent-posts {

    > .dt-item {
      margin-bottom: 25px;
      padding-bottom: 25px;
      border-bottom: 1px solid #d5d8dc;

      &:last-child {
        border-bottom: none; }

      @include clearfix();

      > .item-thumb {
        float: left;
        width: 80px;
        height: 50px;
        overflow: hidden;

        > img {
          width: 100%;
          height: auto; } }

      > .item-content {
        padding-left: 90px;

        > .item-title {
          margin-top: 0;
          text-transform: uppercase;
          font-size: 14px;
          font-weight: 700;
          line-height: 18px;
          margin-bottom: 10px; }

        > .item-date {
          font-size: 14px;
          color: #555555;
          line-height: 1;

          > a {
            color: #555555;
            line-height: 1; } } } } } }

