.dt-search-form {
  position: relative;
  height: 32px;
  margin-bottom: 40px;

  > input {
    position: absolute;
    width: 100%;
    height: 100%;
    border: none;
    padding-right: 30px;
    padding-left: 10px; }

  button {
    position: absolute;
    border: none;
    background-color: transparent;
    top: 50%;
    right: 5px;
    transform: translateY(-50%); } }
