.dt-breadcrumbs {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;

  @include clearfix();

  > li {
    display: inline-block;
    position: relative;

    > a {
      color: #000; }

    &:after {
      content: '/';
      padding-left: 5px;
      display: inline-block; }

    &:last-child:after {
      display: none; } } }
