.dt-share {
  padding: 0;
  line-height: 1em;

  > li {
    display: inline-block;
    font-size: 0.7777777777777778em;
    line-height: 1em;
    margin-right: 10px;

    &:last-child {
      margin-right: 0; }

    &.share-text {
      text-transform: uppercase; }

    > a {
      color: #555555; } } }
