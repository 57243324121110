.dt-videos-list {

  &[data-column="2"] {
    > div {
      margin-bottom: 45px;

      &:nth-child(odd) {
        clear: both; }
      &:nth-child(even) {
        clear: none; } } } }

.dt-video-item {
  margin-bottom: 25px;
  @media (max-width: 1024px) {
    max-width: 460px;
    margin-right: auto;
    margin-left: auto; }

  &:last-child {
    margin-bottom: 0; }

  .dt-video-thumb {
    position: relative;
    overflow: hidden;

    > img {
      width: 100%;
      height: auto; }

    > a {
      display: block;
      width: 75px;
      height: 45px;
      position: absolute;
      bottom: 0;
      left: 0;
      margin-bottom: 20px;
      margin-left: 20px;
      background-image: url(../img/play_icon.png);
      background-repeat: no-repeat;

      &:hover {
        opacity: 0.5; }

      > span {
        visibility: hidden; } } }

  .dt-video-title {
    display: block;
    margin-top: 20px;
    padding-bottom: 15px;
    padding-right: 20px;
    padding-left: 20px;
    border-bottom: 1px solid #000;
    line-height: 1.3em;
    text-align: center;
    text-transform: uppercase;
    position: relative;

    &:before,
    &:after {
      content: '';
      position: absolute;
      width: 1px;
      height: 70%;
      background-color: #000;
      bottom: 0; }

    &:before {
      left: 0; }
    &:after {
      right: 0; }

    > a {
      color: #000;
      text-decoration: none; } }

  .dt-video-description {
    margin-top: 25px; } }
