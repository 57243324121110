> div {
  > ul {
    list-style: none;
    padding-left: 0;

    > li {
      margin-bottom: 30px;
      a {
        color: #FFF;
        font-size: 1.3333em;
        line-height: 1.3em;
        text-transform: uppercase;

        &:hover {
          border-bottom: 2px solid #FFF;
          text-decoration: none; }

        &:focus {
          text-decoration: none; } }

      &.current-menu-item {
        > a {
          font-weight: bold;
          border-bottom: 2px solid #FFF; } }

      // Sub menu
      > ul {
        padding-left: 10px;
        padding-top: 25px;
        list-style: none;
        display: none;

        > li {
          > a {
            font-size: 1em;
            line-height: 1.8em;
            text-transform: inherit; } } }

      &.menu-item-has-children {
        > a:after {
            content: "+";
            margin-left: 5px; } }
      &.submenu-active {
        > a:after {
          content: "-"; }

        > ul {
          display: block; } } } } }
