.dt-error-page-section {
  height: 100vh;
  padding: 60px;
  overflow: hidden;

  > .dt-error-container {
    height: 100%;
    border: 3px solid #000;
    position: relative;

    > img {
      width: 100%;
      height: auto;
      margin-top: -65px;
      margin-bottom: -65px;
      margin-left: -65px;
      transform: translateX(-25%); } }

  .dt-error-overlay {
    position: absolute;
    background-color: rgba(0,0,0,0.5);
    top: 0;
    left: -65px;
    right: 40%;
    height: 100%;
    padding: 175px 150px 175px 50px;

    > .content {
      background-color: #FFF;
      height: 100%;
      box-sizing: border-box;
      padding: 80px 60px 80px 0;
      display: flex;
      align-items: center;
      justify-content: center;
      text-transform: uppercase;

      > .info {
        width: 50%;
        padding-left: 45px;

        > .title {
          color: #000; }

        > .subtitle {
          font-size: 1.6em; }

        > .dt-back {
          margin-top: 50px;

          > a {
            font-size: 1.333333333333333em;
            text-decoration: underline; } } }

      > img {
        width: 50%; } } }

  .dt-error-social {
    position: absolute;
    right: 100px;
    top: 50%;
    transform: translateY(-50%);

    > .title {
      font-weight: normal;
      text-transform: uppercase;
      color: #000;
      margin-bottom: 50px; } } }

@media (max-width: 1024px) {
  .dt-error-page-section {

    > .dt-error-container {
      overflow: hidden;
      > img {
        margin: 0;
        transform: none; } }

    .dt-error-overlay {
      left: 0;
      padding: 50px;
      right: 0; }

    .dt-error-social {
      transform: none;
      top: auto;
      bottom: 100px;
      right: auto;
      left: 51.5%;

      > .title {
        margin-bottom: 10px; }

      > ul {
        text-align: left;
        > li {
          display: inline-block;
          margin-right: 10px; } } } } }

@media (max-width: 768px) {
  .dt-error-page-section {
    height: auto;
    padding: 0;

    > .dt-error-container {

      > img {
        height: 975px;
        width: auto; } }

    .dt-error-overlay {

      > .content {
        display: block;
        padding: 50px;
        text-align: center;

        > img {
          width: 100%; }

        > .info {
          width: 100%;
          padding: 0; } } }


    .dt-error-social {
      width: 100%;
      left: 0;
      text-align: center;

      > ul {
        text-align: center; } } } }

@media (max-width: 480px) {
  .dt-error-page-section {
    padding: 0;

    > .dt-error-container > img {
      height: 750px; }

    .dt-error-overlay {
      padding: 25px; } } }
