.dt-btn {
  position: relative;
  display: inline-block;
  background-color: #000;
  color: #FFF;
  padding: 7px 15px;
  line-height: 1em;
  border-bottom: 1px solid transparent;
  margin: 5px 0;

  &.dt-btn-medium {
    padding: 15px 50px; }

  &:before, &:after {
    content: "";
    position: absolute;
    height: 100%;
    width: 1px;
    opacity: 0;
    transition: all 0.3s 0.1s ease-in-out; }

  &:before {
    left: 0;
    bottom: 0; }

  &:after {
    right: 0;
    bottom: 0; }

  &:hover,
  &:focus,
  &.active {
    border-bottom-color: #000;
    text-decoration: none;
    color: #000;
    background-color: transparent;

    &:before, &:after {
      height: 50%;
      opacity: 1;
      background-color: #000; } }

  &.alt {
    background-color: #ebeef3;
    color: #000;
    border: 1px solid transparent;

    &:before,
    &:after {
      display: none; }

    &:hover,
    &.active {
      border-color: #000;
      background-color: transparent; } } }
