.widget_dt_about_us {
  .dt-company-logo {
    width: 60px;
    float: left; }

  .dt-about-us-content {
    padding-left: 110px; }

  .list-2column {
    padding-left: 0;
    padding-top: 20px;

    > li {
      list-style: none;
      width: 50%;
      float: left;
      line-height: 1;
      margin-bottom: 25px;

      > a {
        text-transform: uppercase;
        font-size: 14px;
        color: #000; } } } }
