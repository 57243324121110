.dt-comment-action {
  margin: 0;
  padding: 0;
  line-height: 1em;

  > li {
    display: inline-block;
    margin-right: 25px;

    &:last-child {
      margin-right: 0; }

    > a {
      font-size: 0.7777777777777778em;
      color: #555555;

      > i {
        margin-right: 5px; }

      > span {
        text-transform: uppercase; }

      &:hover {
        color: $accent; } } } }
