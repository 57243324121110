.dt-project {
  padding-bottom: 50px;
  margin-bottom: 50px;
  border-bottom: 1px solid #cccccc;
  @include clearfix();

  &:last-child {
    border-bottom: none;
    margin-bottom: 0; }

  > .dt-project-featured {
    width: 315px;
    float: left;

    > img {
      width: 100%;
      height: auto; } }

  > .dt-project-header,
  > .dt-project-content,
  > .dt-project-footer {
    padding-left: 350px; }

  > .dt-project-header {
    > .dt-title {
      margin-top: 0;
      margin-bottom: 30px;

      > a {
        display: block;
        color: #000; }
      > small {
        color: #000; } }

    > .dt-project-meta {
      > ul {
        padding: 0;
        margin-bottom: 20px;

        > li {
          list-style: none;
          color: #000;

          > span {
            font-weight: bold;
            text-transform: uppercase; } } } } }

  > .dt-project-content {
    margin-bottom: 25px;
    > a {
      text-decoration: underline; } }

  > .dt-project-footer {
    > .dt-project-meta {
      > ul {
        padding: 0;
        margin: 0;

        > li {
          list-style: none;

          > span {
            font-weight: bold;
            text-transform: uppercase; }
          > a {
            text-decoration: underline; } } } } } }
