.dt-masonry-blog-grid {
  margin-left: -20px;
  margin-right: -20px;

  > .dt-gallery-item {
    width: auto;
    height: auto;
    padding: 20px;

    > .dt-item-info {
      position: relative;

      &:before {
        content: '';
        background-color: rgba(255,255,255, 0.7);
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0%;
        left: 0;
        z-index: 1;
        opacity: 0;
        transition: opacity 0.15s ease-in-out; }

      &:after {
        content: '';
        position: absolute;
        top: 40px;
        right: 40px;
        bottom: 40px;
        left: 40px;
        border: 1px solid #000;
        z-index: 2;
        opacity: 0;
        transition: opacity 0.15s ease-in-out; }

      > .dt-info-content {
        text-align: center;
        z-index: 3;
        color: #000;

        a {
          color: #000; } } }

    &:hover {
      > .dt-item-info:before,
      > .dt-item-info:after {
        opacity: 1; } } } }
