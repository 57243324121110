.dt-main-slider {
  position: relative;

  @media (max-width: 1024px) {
    padding-right: 40px; }

  [data-swiper="pagination"] {
    position: absolute;
    top: 50%;
    right: -80px;
    width: 80px;
    z-index: 100;
    transform: translateY(-50%);

    @media (max-width: 1024px) {
      width: 40px;
      right: 0px; }

    > span {
      width: 22px;
      height: 22px;
      display: block;
      background-color: transparent;
      border: 1px solid #000;
      opacity: 1;
      margin: 0 auto;
      margin-bottom: 45px;
      transition: all 0.15s ease-in-out;

      @media (max-width: 768px) {
        width: 15px;
        height: 15px;
        margin-bottom: 25px; }

      &:hover,
      &[class*="active"] {
        background-color: #000; }

      &:last-child {
        margin-bottom: 0; } }

    &.left {
      right: auto;
      left: -80px; } }

  .dt-section-switch {
    position: absolute;
    bottom: 45px;
    left: 50%;
    transform: translateX(-50%);

    @media (max-width: 768px) {
      bottom: 5px;

      > .dt-angle-shape {
        width: 30px;
        height: 17.5px;
        background-size: 100%; } } } }
