.widget_dt_links {
  .dt-links {
    list-style: none;
    padding-left: 0;

    li {
      text-transform: uppercase;
      margin-bottom: 40px;

      &:last-child {
        margin-bottom: 0; }

      > a {
        color: #000; } } } }
