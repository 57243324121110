.dt-audio-stream {
  position: relative;
  border: 1px solid #dad7d7;
  padding: 40px;
  margin-bottom: 30px;
  @media (max-width: 768px) {
    padding: 25px 15px; }


  &:hover {
    background-color: #f2f2f2; }

  > .dt-media-thumb {
    width: 120px;
    height: 90px;
    overflow: hidden;
    float: left;

    @media (max-width: 768px) {
      float: none;
      margin: 0 auto 25px auto; }

    > img {
      width: 100%;
      height: auto; } }

  > .dt-media-content {
    padding-left: 165px;
    position: relative;

    @media (max-width: 768px) {
      padding-left: 0; }

    > .dt-title {
      margin-top: 0;
      margin-bottom: 2px;
      font-weight: bold;
      text-transform: uppercase;
      color: #000; }


    > audio {
      width: 100%; } } }
