blockquote,
.blockquote, {
  padding: 40px 100px;
  margin-bottom: 50px;
  border: 1px solid #000;
  position: relative;
  font-size: 1.111111111111111em;
  font-style: italic;

  &:before {
    content: "\2019 \2019";
    font-family: sans-serif;
    position: absolute;
    top: 20px;
    left: 32px;
    font-size: 80px;
    line-height: 1em;
    font-weight: bold;
    font-style: normal;
    color: #000; }

  @media (max-width: 768px) {
    padding: 20px 30px;

    &:before {
      font-size: 40px;
      top: 10px;
      left: 10px; } } }
