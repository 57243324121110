.dt-site-footer {
  border-top: 1px solid #000;
  padding-top: 50px;


  .dt-copyrigts-info {
    padding: 30px 0;
    border-top: 1px solid #e1e1e1; }

  .dt-back-to-top {
    display: table;
    width: 100px;
    margin: 0 auto 25px auto;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      width: 30px;
      height: 20px;
      left: 50%;
      margin-left: -15px;
      top: -25px;
      background-image: url(../img/arrow-up.png);
      background-repeat: no-repeat;
      background-position: center center; } }

  &.footer-personal {
    background-color: #ebeef3;
    border-top: none;
    padding-top: 100px;


    .dt-copyrigts-info {
      border-top: none; }

    .widget {
      > .widget-title {
        border: none;
        margin-bottom: 25px;
        padding: 0; } }

    .footer-copyright-area {
      background-color: #FFF; } } }

@media (max-width: 768px) {
  .dt-site-footer {

    .widget {
      margin-bottom: 25px !important;
      border-bottom: 1px solid #e1e1e1;
      padding-bottom: 25px;

      &.widget_dt_links {
        .dt-links {
          text-align: center; } } }

    .dt-copyrigts-info {

      > div, ul {
        float: none !important;
        text-align: center;
        margin-bottom: 25px; } }

    .last-widget .widget {
      border-bottom: 0; } } }
