.widget {
  .dt-recent-tweets {

    > .dt-item {
      margin-bottom: 25px;
      padding-bottom: 25px;
      border-bottom: 1px solid #d5d8dc;

      &:last-child {
        border-bottom: none; }

      > span {
        display: block; } } } }
