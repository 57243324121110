// @media (min-width: 1200px)

@media (max-width: 992px) {

  // Carousel
  // .dt-item-carousel
  //   padding: 0 100px
  //   [data-swiper="prev"],
  //   [data-swiper="next"]
 }  //     width: 100px

@media (max-width: 768px) {
  // Blog Entries
  .dt-blog-entries[data-column="2"] .dt-post {
      width: 100%; }

  // Carousel
  // .dt-item-carousel
  //   padding: 0
  //   [data-swiper="prev"],
  //   [data-swiper="next"]
 }  //     display: none
