#respond {
  > .dt-title {
    font-size: 1em;
    padding: 10px 0;
    margin-bottom: 50px;
    color: #000; } }

#commentform {
  > .comment-form-comment {
    margin-bottom: 25px;

    > label {
      color: #000;
      font-weight: normal;
      margin-bottom: 0; }

    > textarea {
      width: 100%;
      height: 100px;
      border: 1px solid #000;
      border-top: 0px;
      padding: 10px;

      &:focus {
        outline-color: transparent; } } }
  > .comment-form-author,
  > .comment-form-email {
    margin-bottom: 30px;
    width: 50%;
    float: left;

    > label {
      color: #000;
      font-weight: normal;
      margin-bottom: 0; }

    > input {
      display: block;
      width: 100%;
      border: 1px solid #000;
      border-top: none;
      padding: 0 10px;

      &:focus {
        outline-color: transparent; } } }

  > .comment-form-email {
    padding-left: 20px; }

  > .comment-form-author {
    padding-right: 20px; }

  > .form-submit {
    width: 50%;
    padding-right: 20px;

    > input {
      display: block;
      width: 100%;
      background-color: #000;
      color: #FFF;
      border: 1px solid #000;
      padding: 12px 20px;
      line-height: 1em;
      transition: all 0.15s ease-in-out;

      &:hover {
        color: #000;
        border-top-color: transparent;
        background-color: transparent; } } } }

