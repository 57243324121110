.dt-photo-gallery {
  // width: 1760px
  margin: 0 auto; }

.dt-gallery-item {
  position: relative;
  width: 18.86363636363636%;
  height: 500px;
  overflow: hidden;

  @media (max-width: 1024px) {
    height: 290px; }
  @media (max-width: 768px) {
    width: 49.9%;
    height: auto; }

  > img {
    width: 102%;
    height: auto;
    transition: all 0.5s ease-in-out; }

  > .dt-item-info {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,0.0);
    transition: background 0.15s ease-in-out;

    > .dt-info-content {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #FFF;
      opacity: 0;

      > h3,
      > ul {
        transform: translateY(250%);
        opacity: 0;
        transition: all 0.25s cubic-bezier(0.18, 0.89, 0.32, 1.28); }

      > ul {
        transition-delay: .05s; } }
    a {
      color: #FFF; } }
  &:hover {
    > img {
      transform: scale(1.1) rotate(2deg); }
    > .dt-item-info {
      background-color: rgba(0,0,0,0.7);

      > .dt-info-content {
        opacity: 1;

        > h3,
        > ul {
          opacity: 1;
          transform: translateY(0%); } } } }


  // Gallery Title
  > .dt-gallery-title {
    position: absolute;
    width: 100%;
    height: 250px;
    background-color: transparent;

    > .dt-info-content {
      opacity: 1;
      > h3,
      > ul {
        transform: translate(0);
        opacity: 1; }
      a {
       color: $accent; } }

    &:hover {
      background-color: transparent;

      > .dt-info-content {
        opacity: 1; } }

    &.style-2 {
      background-color: #f8f9fb;

      &:before {
        content: '';
        position: absolute;
        top: 35px;
        right: 35px;
        bottom: 35px;
        left: 35px;
        border: 1px solid #000; }

      > .dt-info-content {
        width: 100%;
        color: #000; } } }


  // Sizes
  &.size-2 {
    width: 37.72727272727273%;
    @media (max-width: 768px) {
      width: 49.9%;
      height: auto; } }

  &.size-3 {
   width: 24.43181818181818%;
   height: 250px;
   @media (max-width: 1024px) {
    height: 145px; }
   @media (max-width: 768px) {
    width: 49.9%;
    height: auto; } }

  &.size-4 {
    width: 24.43181818181818%;
    height: 500px;
    @media (max-width: 1024px) {
      height: 290px; }
    @media (max-width: 768px) {
      width: 49.9%;
      height: auto; } }

  &.size-5 {
    width: 37.72727272727273%;
    height: 1000px;
    @media (max-width: 1024px) {
      height: 590px; }
    @media (max-width: 768px) {
      width: 49.9%;
      min-height: 125px; } }

  &.size-6 {
    width: 24.43181818181818%;
    height: 500px;
    @media (max-width: 1024px) {
      height: 290px; }
    @media (max-width: 768px) {
      width: 49.9%;
      height: auto; } }

  &.dt-title-item {
    height: 250px; } }
