.dt-social-icons {
  padding: 0;

  > li {
    list-style: none;
    line-height: 1.7em;

    a {
      color: #000;
      opacity: 0.7;

      &:hover {
        opacity: 1; } } } }
