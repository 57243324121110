.widget {
  .dt-latest-comments {
    > .dt-item {
      border-bottom: 1px solid #cccccc;
      margin-bottom: 30px;

      &:last-child {
        border-bottom: none;
        margin-bottom: 0; }

      > .dt-comment-info {
        margin-bottom: 20px;

        > .dt-avatar {
          width: 66px;
          height: 66px;
          float: left; }

        > .dt-item-meta {
          padding-left: 86px;

          > .dt-title {
            font-size: 1em;
            line-height: 1.333333333333333em;
            margin-top: 0;
            margin-bottom: 5px; }

          > .dt-date {
            font-size: 0.8888888888888889em;
            line-height: 1.2em;
            margin-bottom: 10px; }

          > .dt-on-post {
            font-size: 0.7777777777777778em;
            line-height: 1.2em;
            text-transform: uppercase; } } }

      > .dt-content {
        margin-bottom: 15px; }

      > .dt-item-action {
        margin-bottom: 20px;
        position: relative;

        > .dt-comment-love {
          position: absolute;
          top: 0;
          right: 0;
          font-size: 0.7777777777777778em;

          > a {
            color: #555555; } } } } } }
