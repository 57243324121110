.dt-projects-calendar {
  width: 100%;

  > caption {
    position: relative;
    background-color: #000;
    color: #FFF;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    font-size: 0.7777777777777778em;
    padding: 0;

    > a {
      position: absolute;
      width: 30px;
      height: 100%;
      background-color: #FFF;
      border: 1px solid #000;
      top: 0;

      &.prev-month {
        left: 0;

        &:before {
          content: "<";
          position: relative;
          top: -2px; } }

      &.next-month {
        right: 0;

        &:before {
          content: ">";
          position: relative;
          top: -2px; } }

      &:hover {
        text-decoration: none; } } }

  thead {
    border-bottom: 1px solid $accent;
    tr {
      th {
        text-align: center;
        font-weight: normal;
        padding: 10px 0; } } }

  tbody {
    > tr {
      > td {
        text-align: center;
        padding: 4px 6px;

        &.today {
          font-weight: bold; }

        &.old {
          opacity: 0.4; }

        > a {
          position: relative;
          display: block;
          overflow: hidden;

          > .overlay-content {
            position: absolute;
            background-color: rgba(0,0,0,.8);
            display: block;
            padding: 10px;
            width: 160px;
            left: -60px;
            color: #FFF;
            margin-top: 10px;
            transform: translateY(100%);
            opacity: 0;
            transition: all 0.35s cubic-bezier(0.18, 0.89, 0.32, 1.28);

            &:before {
              content: '';
              position: absolute;
              top: -10px;
              left: 50%;
              width: 0;
              height: 0;
              border-style: solid;
              border-width: 0 10px 10px 10px;
              border-color: transparent transparent rgba(0,0,0,.8) transparent;
              transform: translateX(-50%); } }

          &:hover {
            color: #FFF;
            background-color: #333;
            text-decoration: none;
            overflow: visible;

            > .overlay-content {
              transform: translateY(0);
              opacity: 1; } } } }


      &:first-child {
        > td {
          padding-top: 10px; } } } } }
