.dt-content-block {
  margin-bottom: 115px;

  > .row {
    @media (min-width: 769px) {
      display: flex; }

    > .content {
      @media (min-width: 769px) {
        display: flex;
        align-items: center;
        justify-content: center; }

      > .inside {

        > .dt-title {
          color: #000;
          margin-bottom: 20px; } } }

    > .graphic {
      z-index: 100;

      > img {
        width: 100%;
        height: auto; } } }

  &.left-graphic {
    .row > .content > .inside {
      z-index: 101;
      border: 1px solid #000;
      padding: 65px 90px;
      margin-bottom: 25px;
      @media (min-width: 769px) {
        margin-bottom: 0px;
        transform: translateX(50px); } } } }
