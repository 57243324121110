#dt-site-header {

  // Sliding Menu
  &.sliding-menu-enabled {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    z-index: 901;
    transition: all 0.7s cubic-bezier(0.68, -0.55, 0.27, 1.55);

    // Logo and Social
    #dt-logo-and-social {
      width: 80px;
      height: 100%;
      padding-top: 50px;
      padding-bottom: 75px;
      background-color: #FFF;
      box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.09);

      @media (max-width: 1024px) {
        // transform: translateX(-80px)
        // opacity: 0
        transition: all 0.25s ease-in-out; } }

    // Menu Trigger
    #dt-menu-trigger {
      margin-right: auto;
      margin-left: auto;
      margin-bottom: 50px;

      @media (max-width: 1024px ) {
        transform: translateX(80px); }
      //   > span
 }      //     background-color: #737373

    // Logo
    #dt-logo {
      // @media (max-width: 1024px)
      //   display: none

      > a {
        display: block;
        text-align: center; } }
    // Social Icon
    .dt-social-icons {
      position: absolute;
      left: 0;
      bottom: 70px;
      right: 0;

      // @media (max-width: 1024px)
 }      //   display: none

    // Site Menu
    #dt-site-menu {
      position: absolute;
      padding: 50px 80px;
      top: 0;
      left: -395px;
      bottom: 0;
      width: 395px;
      height: 100%;
      background-color: rgba(0,0,0,0.9);

      // Import Search Form Code
      @import "elements/menu"; }

    #dt-search-and-links {
      position: absolute;
      bottom: 70px;
      left: 80px;
      right: 80px;

      // Import Search Form Code
      @import "elements/search-form";

      // Import Quick Links Code
      @import "elements/quick-links"; } } }

// Menu Open
.dt-menu-open #dt-site-header {

    // Sliding menu
    &.sliding-menu-enabled {
      transform: translateX(395px);

      @media (max-width: 1024px) {
        #dt-logo-and-social,
        #dt-menu-trigger {
          transform: translateX(0px); } } } }

@media (max-width: 768px) {
  #dt-site-header {
    width: 100%;

    &.sliding-menu-enabled {
      left: -100%;
      // margin-left: 80px
      transition: all 0.7s ease-in-out;

      #dt-logo-and-social {
        position: absolute;
        z-index: 101;
        right: 0; }

      #dt-site-menu {
        position: relative;
        left: auto;
        top: auto;
        width: 100%;
        height: 100%;
        overflow: scroll;
        z-index: 100;
        opacity: 0;
        padding-right: 160px;
        transition: opacity 0.7s ease-in-out; }

      #dt-search-and-links {
       position: relative;
       right: auto;
       bottom: auto;
       left: auto; } } }


  .dt-menu-open {
    transform: none;

    #dt-site-header.sliding-menu-enabled {
      left: 0;
      margin-left: 0;
      transform: none;

      #dt-site-menu {
        opacity: 1; }

      // #dt-menu-trigger > span
 } } }      //   background-color: #FFF

@media (max-width: 375px) {
  #dt-site-header.sliding-menu-enabled #dt-site-menu {
    padding-top: 30px;
    padding-right: 100px;
    padding-bottom: 30px;
    padding-left: 30px; } }
