.dt-post {
  margin-bottom: 100px;
  @include clearfix();

  @media (max-width: 768px) {
    max-width: 460px;
    margin-right: auto;
    margin-left: auto;
    padding: 0 15px;

    [class^="col-"] > & {
      padding: 0; } }

  .dt-entry-header {
    > .dt-title {
      margin-top: 0;
      margin-bottom: 15px;
      text-transform: uppercase;
      color: #000;

      > a {
        display: block;
        color: #000;
        margin-bottom: 5px; }

      > small {
        display: block;
        font-size: 0.75em;
        text-transform: uppercase;
        color: #000; } }

    > .dt-entry-meta {
      > ul {
        padding: 0;

        > li {
          display: inline-block;

          &:after {
            content: '';
            display: inline-block;
            height: 12px;
            width: 1px;
            background-color: #000;
            margin-right: 4px;
            margin-left: 10px; }

          &:last-child:after {
            display: none; } } } }
    > .dt-entry-featured {
      margin-bottom: 30px;
      > img {
        width: 100%;
        height: auto; }

      &.border-style {
        border: 1px solid #000;
        padding: 40px;
        overflow: hidden; } } }

  .dt-entry-content {
    margin-bottom: 15px;

    > img {
      margin-right: 35px;
      margin-left: 35px;
      margin-bottom: 35px;

      &.pull-left {
        margin-left: 0; } }

    .dt-image-carousel {
      margin: 50px 115px;

      @media (max-width: 768px) {
        margin: 50px 30px; } }

    p,
    .dt-video-item {
      margin-bottom: 50px; } }

  .dt-entry-footer {
    @include clearfix();

    > .dt-entry-more {
      float: left;
      line-height: 1em; }

    > .dt-entry-category {
      line-height: 1em;
      float: right;
      text-align: right; } }

  &.full-width {
    .dt-video-item,
    .blockquote,
    blockquote {
      margin: 50px 60px;

      @media (max-width: 768px) {
        margin: 50px 20px; } }

    .dt-audio-stream {
      margin: 30px 175px;

      @media (max-width: 768px) {
        margin: 50px 20px; } } }

  &.personal {
    .dt-entry-content {
      margin-bottom: 15px;

      .dt-image-carousel {
        margin: 50px 0px; } } } }

@media (max-width: 768px) {
  [data-isotope="container"] {
    .dt-post {
      width: 100%;
      max-width: inherit;

      > .dt-entry-header,
      > .dt-entry-content,
      > .dt-entry-footer {
        max-width: 460px;
        margin: 0 auto; } } } }
