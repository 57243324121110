.offline-page {
  margin: 0px; }
.dt-offline-section {
  height: 100vh;
  overflow: hidden;

  @include clearfix();

  > div {
    height: 100%;
    position: relative;

    &.dt-offline-content {
      width: 40%;
      float: left;
      text-align: right;
      padding-top: 10vh;
      padding-right: 25px;

      > img {
        position: absolute;
        bottom: 50px;
        right: 50px;
        width: 10vw; }

      > .subtitle {
        text-transform: uppercase;
        color: #000;
        font-size: 1.0vw;
        margin-bottom: 0; }

      > .title {
        text-transform: uppercase;
        color: #000;
        font-size: 3.0vw;
        margin-top: 0; } }


    &.dt-offline-graphic {
      width: 60%;
      float: left;
      overflow: hidden;

      > img {
        height: 100%;
        width: auto; }

      > .dt-social-icons {
        position: absolute;
        left: 50px;
        bottom: 150px;

        > li {
          text-align: center; } } } } }
