.dt-countdown-timer {
  display: flex;
  justify-content: center;
  align-items: center;

  > div {
    padding: 0 40px;
    font-size: 1.333333333333333em;

    > span {
      padding-left: 10px;
      padding-right: 10px;
      display: block;
      border-bottom: 1px solid #000;
      position: relative;
      font-size: 2em;
      line-height: 1em;
      margin-bottom: 5px;

      &:before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        height: 50%;
        width: 1px;
        background-color: #000; }

      &:after {
        content: "";
        position: absolute;
        bottom: 0;
        right: 0;
        width: 1px;
        height: 50%;
        background-color: #000; } } } }
