.dt-quick-links {
  padding: 0;
  text-align: center;

  > li {
    display: inline-block;
    margin-bottom: 0;
    line-height: 1em;
    border-right: 2px solid #FFF;
    padding-right: 10px;
    padding-left: 5px;

    > a {
      font-size: 1em;

      &:hover {
        font-weight: normal; } }
    &:first-child {
      padding-left: 0; }

    &:last-child {
      border-right: none;
      padding-right: 0; } } }
