.dt-comments-section {
  > .dt-title {
    margin-bottom: 50px;
    color: #000;
    padding: 15px 0; } }

#comments {
  > .comment-list {
    list-style: none;
    padding: 0;

    li.comment {

      &.bypostauthor .dt-author-photo:after {
        content: 'Editor';
        font-size: 0.6666666666666667em;
        display: block;
        border: 1px solid #000;
        border-top: none;
        text-align: center;
        line-height: 1.6em; }

      > .dt-comment-body {
        margin-bottom: 50px;

        > .dt-author-photo {
          width: 64px;
          height: 64px;
          float: left;

          > img {
            width: 100%;
            height: 100%; } }


        > .dt-comment-content {
          padding-bottom: 50px;
          border-bottom: 1px solid #cccccc;
          padding-left: 80px;

          > .dt-comment-meta {
            margin-bottom: 15px;

            > .dt-comment-author {
              font-weight: bold;
              text-transform: uppercase;
              color: #000;
              margin-top: 0;
              margin-bottom: 10px;
              line-height: 1em;

              > a {
                color: #000; } }
            > .dt-comment-info {
              line-height: 1em;

              > .dt-comment-date {
                font-size: 0.7777777777777778em;
                line-height: 1em; } } }

          > .dt-content {
            margin-bottom: 15px; } } }

      > .children {
        list-style: none;
        padding-left: 80px; }

      &:last-child li.comment:last-child {

        > .dt-comment-body {
          > .dt-comment-content {
            border-bottom: none; } } } } } }
