.dt-gallery-and-info {
  > div > .dt-image-carousel {
    margin-bottom: 50px; }

  > .dt-center-div {

    > .dt-gallery-info {

      > .content {
        margin-bottom: 50px; }

      > table.meta {
        width: 100%;
        tr {
          th {
            padding-right: 17.5px;
            text-align: right;
            font-weight: bold;
            text-transform: uppercase;
            padding-bottom: 20px; }
          td {
            padding-left: 17.5px;
            padding-bottom: 20px;

            > a {
              text-decoration: underline; } } } } } } }

@media (min-width: 768px) {
  .dt-gallery-and-info {
    display: flex;

    .dt-center-div {
      display: flex;
      align-items: center;
      justify-content: center; } } }
