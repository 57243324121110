.widget {
  margin-bottom: 50px;

  &:last-child {
    margin-bottom: 0; }

  > .widget-title {
    margin-top: 0;
    margin-bottom: 35px;
    color: #000;
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
    padding-top: 12px;
    padding-bottom: 12px; }

 }  // > .dt-widget-container
