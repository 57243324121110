.dt-section-title {
    position: relative;
    display: inline-block;
    color: #000;
    border-bottom: 1px solid #000;
    padding-right: 25px;
    padding-bottom: 10px;
    padding-left: 25px;

    &:before, &:after {
      content: "";
      position: absolute;
      height: 60%;
      width: 1px;
      background-color: #000; }

    &:before {
      left: 0;
      bottom: 0; }

    &:after {
      right: 0;
      bottom: 0; } }

.dt-section-title-style-2 {
    border-top: 2px solid #000;
    border-bottom: 2px solid #000;
    position: relative;
    padding: 12px 0;

    > h2 {
      color: #000;
      margin: 0; }

    > .dt-sub-link {
      @media (min-width: 480px) {
        position: absolute;
        top: 50%;
        right: 0px;
        transform: translateY(-50%); } } }

.dt-section-title-style-3 {
    border-bottom: 1px solid #000;
    position: relative;
    padding: 12px 0;

    > h2 {
      color: #000;
      margin: 0; }

    > .dt-sub-link {
      @media (min-width: 480px) {
        position: absolute;
        top: 50%;
        right: 0px;
        transform: translateY(-50%); } } }
