.dt-author-profile {
  @include clearfix();
  > .dt-profile-img {
    width: 315px;
    height: 315px;
    overflow: hidden;
    float: left;
    @media (max-width: 768px) {
      float: none;
      margin-right: auto;
      margin-left: auto;
      margin-bottom: 40px; }

    > img {
      width: 100%;
      height: auto; } }
  > .dt-profile-info {
    padding-left: 350px;
    @media (max-width: 768px) {
      padding: 0; }

    > .dt-author-name {
      font-weight: bold;
      color: #000;
      margin-top: 0;
      margin-bottom: 20px;
      line-height: 1em; }

    > .dt-author-job-and-link {
      overflow: hidden;
      margin-bottom: 20px;
      line-height: 1em;

      > .dt-name {
        float: left; }
      > .dt-link {
        float: right; } }

    > .dt-social-icons {
      margin-bottom: 30px;
      line-height: 1em;

      > li {
        line-height: 1em; } }

    > .dt-author-bio {
      padding: 47px 100px;
      border: 1px solid #000;
      position: relative;
      font-size: 1.111111111111111em;
      font-style: italic;
      margin-bottom: 45px;
      @media (max-width: 768px) {
        padding: 20px 25px; }

      &:before {
        content: "\2019 \2019";
        font-family: sans-serif;
        position: absolute;
        top: 20px;
        left: 32px;
        font-size: 80px;
        line-height: 1em;
        font-weight: bold;
        font-style: normal;
        color: #000;
        @media (max-width: 768px) {
          top: 10px;
          left: 10px;
          font-size: 40px; } } }

    p {
      margin-bottom: 50px; }

    .dt-video-item {
      margin-left: 120px;
      margin-right: 120px; } } }
