#wp-calendar {
  width: 100%;
  position: relative;

  caption {
    text-align: center;
    font-weight: bold;
    text-transform: uppercase;
    color: #FFF;
    background-color: #000;
    padding: 0; }

  thead {
    tr {
      border-bottom: 1px solid $accent;
      th {
        text-align: center;
        color: #000;
        font-weight: normal;
        padding-top: 10px;
        padding-bottom: 10px;
        margin-bottom: 10px; } } }

  tbody {
    color: #000;
    td {
      text-align: center;
      padding: 8px; }
    .pad {
      background: none; } }

  tfoot {
    #prev,
    #next {
      a {
        display: inline-block;
        // border: 1px solid #000
        // padding: 0px 10px
        text-transform: uppercase;
        font-size: 0.6em;
        font-weight: bold; } }

    #prev {}
    #next {
      text-align: right; } } }
