.dt-coming-soon-section {
  height: 100vh;
  padding: 60px;
  overflow: hidden;

  .dt-coming-soon-container {
    height: 100%;
    border: 3px solid #000;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    > .dt-coming-soon-image {
      // width: 100%
      // height: auto
      float: left;
      margin-top: -65px;
      margin-bottom: -65px;
      margin-left: -65px;
 }      // transform: translateX(-25%)

    > .dt-countdown-container {
      flex-basis: 100em;
      text-align: center;
      color: #000;

      > .dt-title {
        font-size: 2.666666666666667em;
        margin-bottom: 50px; } } } }

@media (max-width: 1024px) {
  .dt-coming-soon-section .dt-coming-soon-container > .dt-coming-soon-image {
    width: 200%;
    overflow: hidden;

    > img {
      height: 100%;
      width: auto; } } }

@media (max-width: 480px) {
  .dt-coming-soon-section {
    .dt-coming-soon-container {
      display: block;

      > .dt-coming-soon-image {
        display: none; } }

    .dt-countdown-timer {
      display: block;

      > div {
        margin-bottom: 25px; } } } }
