/*--------------------------------------------------------------
  >>> TABLE OF CONTENTS:
  ----------------------------------------------------------------
  # Base rules
  # Elements
    ## Buttons
    ## Ads
    ## Trigger Icon
    ## Social Icon
    ## Section Heading
    ## Section Title
    ## Carousel
    ## Blog
    ## Article
    ## Video
    ## Gallery
    ## Author Profile
    ## Breadcrumbs
    ## Page header
    ## Pagination
    ## Category List
    ## Blockquote
    ## Share
    ## Comment Action
    ## Comment Form
    ## Audio Player
    ## Gallery Info
    ## Content Block
    ## Project
    ## Contact Info
    ## Contact form
    ## Countdown timer
    ## Masonry Blog Grid
  # Sections
    ## Style Iconic
    ## Overlay
  # Sections
    ## Header
    ## Banner
    ## Hot Photos
    ## Popular Article Now
    ## Footer
    ## Comments
    ## Contacts
    ## Offline
    ## Error page
    ## Coming Soon
  # Widgets
    ## Reviews
    ## Latest Comments
    ## Calendar
    ## WordPress Calendar
    ## Popular Tags
    ## About Us
    ## Links
    ## Latest Photos
    ## Recent Tweets
    ## Recent Photos
  # Media Queries
  --------------------------------------------------------------*/
/*--------------------------------------------------------------
  # Base rules
  --------------------------------------------------------------*/
body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  color: #555555;
  font-family: 'Josefin Sans', sans-serif;
  font-size: 18px;
  line-height: 30px;
}

@media (min-width: 1025px) {
  body {
    padding-right: 80px;
    padding-left: 80px;
  }
}

body.no-margin {
  margin-right: 0;
  margin-left: 0;
}

body.no-padding {
  padding-right: 0;
  padding-left: 0;
}

a {
  color: #395687;
  text-decoration: none;
  transition: all 0.15s ease-in-out;
}

a.dt-become-author {
  font-size: 1.333333333333333em;
  padding: 20px 0;
}

.section-padding {
  padding-top: 150px;
}

.dt-section-switch {
  display: block;
  position: relative;
  z-index: 100;
}

.dt-section-switch > .dt-angle-shape {
  display: block;
  width: 60px;
  height: 35px;
  background-image: url(../img/arrow.png);
  background-repeat: no-repeat;
  margin: 0 auto;
}

.dt-section-switch > .dt-angle-shape.alt {
  background-image: url(../img/arrow-alt.png);
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  font-weight: bold;
}

.dt-title {
  text-transform: uppercase;
}

.dt-title > small {
  font-weight: bold;
}

.dt-title.black {
  color: #000;
}

hr {
  border-top-color: #cccccc;
}

hr.dt-line {
  border-top-color: #000;
}

hr.dt-ext-mb {
  margin-bottom: 50px;
}

hr.dt-extra-padding {
  margin-top: 90px;
  margin-bottom: 100px;
}

hr.sep {
  margin-bottom: 50px;
  margin-top: 50px;
}

.dt-tb-line {
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
}

.text-underline {
  text-decoration: underline;
}

/*--------------------------------------------------------------
  # Elements
  --------------------------------------------------------------*/
/*--------------------------------------------------------------
  ## Buttons
  --------------------------------------------------------------*/
.dt-btn {
  position: relative;
  display: inline-block;
  background-color: #000;
  color: #FFF;
  padding: 7px 15px;
  line-height: 1em;
  border-bottom: 1px solid transparent;
  margin: 5px 0;
}

.dt-btn.dt-btn-medium {
  padding: 15px 50px;
}

.dt-btn:before, .dt-btn:after {
  content: "";
  position: absolute;
  height: 100%;
  width: 1px;
  opacity: 0;
  transition: all 0.3s 0.1s ease-in-out;
}

.dt-btn:before {
  left: 0;
  bottom: 0;
}

.dt-btn:after {
  right: 0;
  bottom: 0;
}

.dt-btn:hover, .dt-btn:focus, .dt-btn.active {
  border-bottom-color: #000;
  text-decoration: none;
  color: #000;
  background-color: transparent;
}

.dt-btn:hover:before, .dt-btn:hover:after, .dt-btn:focus:before, .dt-btn:focus:after, .dt-btn.active:before, .dt-btn.active:after {
  height: 50%;
  opacity: 1;
  background-color: #000;
}

.dt-btn.alt {
  background-color: #ebeef3;
  color: #000;
  border: 1px solid transparent;
}

.dt-btn.alt:before, .dt-btn.alt:after {
  display: none;
}

.dt-btn.alt:hover, .dt-btn.alt.active {
  border-color: #000;
  background-color: transparent;
}

/*--------------------------------------------------------------
  ## Ads
  --------------------------------------------------------------*/
.dt-ad-placed > a {
  display: block;
  text-align: center;
}

.dt-ad-placed > a > img {
  margin: 0 auto;
}

.dt-ad-placed.black-bg {
  background-color: #000;
}

.dt-ad-placed.gray-bg {
  background-color: #e5e5e5;
}

/*--------------------------------------------------------------
  ## Trigger Icon
  --------------------------------------------------------------*/
#dt-menu-trigger {
  width: 40px;
  height: 30px;
  position: relative;
  transform: rotate(0deg);
  transition: all 0.5s ease-in-out;
  cursor: pointer;
}

#dt-menu-trigger > span {
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  background: #000;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: all 0.25s ease-in-out;
}

#dt-menu-trigger > span:nth-child(1) {
  top: 0px;
}

#dt-menu-trigger > span:nth-child(2), #dt-menu-trigger > span:nth-child(3) {
  top: 12px;
}

#dt-menu-trigger > span:nth-child(4) {
  top: 24px;
}

#dt-menu-trigger.open > span:nth-child(1) {
  top: 10px;
  width: 0%;
  left: 50%;
}

#dt-menu-trigger.open > span:nth-child(2) {
  transform: rotate(45deg);
}

#dt-menu-trigger.open > span:nth-child(3) {
  transform: rotate(-45deg);
}

#dt-menu-trigger.open > span:nth-child(4) {
  top: 10px;
  width: 0%;
  left: 50%;
}

/*--------------------------------------------------------------
  ## Social Icon
  --------------------------------------------------------------*/
.dt-social-icons {
  padding: 0;
}

.dt-social-icons > li {
  list-style: none;
  line-height: 1.7em;
}

.dt-social-icons > li a {
  color: #000;
  opacity: 0.7;
}

.dt-social-icons > li a:hover {
  opacity: 1;
}

/*--------------------------------------------------------------
  ## Section Heading
  --------------------------------------------------------------*/
.dt-section-heading .dt-section-title,
.dt-section-heading .dt-section-title-style-2 {
  margin-bottom: 90px;
}

.dt-section-heading .dt-section-title-style-3 {
  margin-bottom: 60px;
}

.dt-section-heading .dt-section-description {
  margin-bottom: 90px;
}

/*--------------------------------------------------------------
  ## Section Title
  --------------------------------------------------------------*/
.dt-section-title {
  position: relative;
  display: inline-block;
  color: #000;
  border-bottom: 1px solid #000;
  padding-right: 25px;
  padding-bottom: 10px;
  padding-left: 25px;
}

.dt-section-title:before, .dt-section-title:after {
  content: "";
  position: absolute;
  height: 60%;
  width: 1px;
  background-color: #000;
}

.dt-section-title:before {
  left: 0;
  bottom: 0;
}

.dt-section-title:after {
  right: 0;
  bottom: 0;
}

.dt-section-title-style-2 {
  border-top: 2px solid #000;
  border-bottom: 2px solid #000;
  position: relative;
  padding: 12px 0;
}

.dt-section-title-style-2 > h2 {
  color: #000;
  margin: 0;
}

@media (min-width: 480px) {
  .dt-section-title-style-2 > .dt-sub-link {
    position: absolute;
    top: 50%;
    right: 0px;
    transform: translateY(-50%);
  }
}

.dt-section-title-style-3 {
  border-bottom: 1px solid #000;
  position: relative;
  padding: 12px 0;
}

.dt-section-title-style-3 > h2 {
  color: #000;
  margin: 0;
}

@media (min-width: 480px) {
  .dt-section-title-style-3 > .dt-sub-link {
    position: absolute;
    top: 50%;
    right: 0px;
    transform: translateY(-50%);
  }
}

/*--------------------------------------------------------------
  ## Carousel
  --------------------------------------------------------------*/
.dt-image-carousel {
  position: relative;
}

.dt-image-carousel:hover {
  cursor: -webkit-grab;
}

.dt-image-carousel .swiper-slide {
  position: relative;
}

.dt-image-carousel .swiper-slide > img {
  width: 100%;
  height: auto;
  margin: 0;
}

.dt-image-carousel > [data-swiper="pagination"] {
  position: absolute;
  width: 100%;
  height: 55px;
  background-color: rgba(255, 255, 255, 0.25);
  bottom: 0;
  z-index: 50;
  text-align: center;
  padding: 13px 0;
}

.dt-image-carousel > [data-swiper="pagination"] > span {
  display: inline-block;
  width: 10px;
  height: 10px;
  border: 1px solid #000;
  background-color: transparent;
  opacity: 1;
  transition: all 0.15s ease-in-out;
}

.dt-image-carousel > [data-swiper="pagination"] > span:hover, .dt-image-carousel > [data-swiper="pagination"] > span[class*="active"] {
  background-color: #000;
  border-color: transparent;
}

.dt-image-carousel > [data-swiper="prev"],
.dt-image-carousel > [data-swiper="next"] {
  position: absolute;
  color: #FFF;
  top: 50%;
  z-index: 50;
  width: 28px;
  height: 28px;
  background-color: #000;
  transform: translateY(-50%);
  transition: all 0.15s ease-in-out;
}

.dt-image-carousel > [data-swiper="prev"]:hover,
.dt-image-carousel > [data-swiper="next"]:hover {
  cursor: pointer;
  color: #000;
  background-color: #FFF;
}

.dt-image-carousel > [data-swiper="prev"].swiper-button-disabled,
.dt-image-carousel > [data-swiper="next"].swiper-button-disabled {
  cursor: not-allowed;
}

.dt-image-carousel > [data-swiper="prev"] > i,
.dt-image-carousel > [data-swiper="next"] > i {
  position: absolute;
  font-size: 24px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.dt-image-carousel > [data-swiper="next"] {
  right: 0;
}

.dt-item-carousel {
  background-color: #000;
  padding: 0 320px;
  position: relative;
}

@media (max-width: 1024px) {
  .dt-item-carousel {
    padding: 0 170px;
  }
}

@media (max-width: 768px) {
  .dt-item-carousel {
    padding: 0 150px;
  }
}

@media (max-width: 480px) {
  .dt-item-carousel {
    padding: 0 0px;
  }
}

.dt-item-carousel:hover {
  cursor: -webkit-grab;
}

.dt-item-carousel .swiper-slide .dt-item {
  display: block;
  position: relative;
}

.dt-item-carousel .swiper-slide .dt-item > img {
  width: 100%;
  height: auto;
  margin: 0;
}

.dt-item-carousel .swiper-slide .dt-item > .dt-caption {
  position: absolute;
  color: #FFF;
  width: 100%;
  bottom: 30px;
  padding: 0 35px;
  transform: translateY(100%);
  transition: all 0.5s cubic-bezier(0.18, 0.89, 0.32, 1.28);
  opacity: 0;
}

.dt-item-carousel .swiper-slide .dt-item > .dt-caption .dt-title {
  background-color: rgba(0, 0, 0, 0.7);
  padding: 25px;
  margin: 0;
  color: #FFF;
  font-weight: normal;
}

.dt-item-carousel .swiper-slide .dt-item:hover .dt-caption {
  opacity: 1;
  transform: translateY(0%);
}

.dt-item-carousel [data-swiper="prev"],
.dt-item-carousel [data-swiper="next"] {
  position: absolute;
  top: 50%;
  height: 100%;
  width: 18%;
  z-index: 50;
  transform: translateY(-50%);
  transition: all 0.15s ease-in-out;
}

.dt-item-carousel [data-swiper="prev"] > i,
.dt-item-carousel [data-swiper="next"] > i {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 60px;
  transform: translate(-50%, -50%);
  color: #FFF;
  transition: all 0.35s cubic-bezier(0.18, 0.89, 0.32, 1.28);
}

.dt-item-carousel [data-swiper="prev"].dt-with-title > .dt-title,
.dt-item-carousel [data-swiper="next"].dt-with-title > .dt-title {
  margin: 0;
  font-weight: bold;
  text-align: center;
  color: #FFF;
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 1;
  transition: all 0.35s cubic-bezier(0.18, 0.89, 0.32, 1.28);
}

@media (max-width: 768px) {
  .dt-item-carousel [data-swiper="prev"].dt-with-title > .dt-title,
  .dt-item-carousel [data-swiper="next"].dt-with-title > .dt-title {
    font-size: 1em;
  }
}

@media (max-width: 480px) {
  .dt-item-carousel [data-swiper="prev"].dt-with-title > .dt-title,
  .dt-item-carousel [data-swiper="next"].dt-with-title > .dt-title {
    display: none;
  }
}

.dt-item-carousel [data-swiper="prev"].dt-with-title > i,
.dt-item-carousel [data-swiper="next"].dt-with-title > i {
  transform: translate(-50%, -200%);
  opacity: 0;
}

@media (max-width: 480px) {
  .dt-item-carousel [data-swiper="prev"].dt-with-title > i,
  .dt-item-carousel [data-swiper="next"].dt-with-title > i {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
}

.dt-item-carousel [data-swiper="prev"].dt-with-title:hover > i,
.dt-item-carousel [data-swiper="next"].dt-with-title:hover > i {
  transform: translate(-50%, -50%);
  opacity: 1;
}

.dt-item-carousel [data-swiper="prev"].dt-with-title:hover > .dt-title,
.dt-item-carousel [data-swiper="next"].dt-with-title:hover > .dt-title {
  transform: translate(-50%, 250%);
  opacity: 0;
}

.dt-item-carousel [data-swiper="prev"]:hover,
.dt-item-carousel [data-swiper="next"]:hover {
  cursor: pointer;
}

.dt-item-carousel [data-swiper="prev"]:hover > i,
.dt-item-carousel [data-swiper="next"]:hover > i {
  opacity: 0.5;
}

.dt-item-carousel [data-swiper="prev"] {
  left: 0;
}

.dt-item-carousel [data-swiper="next"] {
  right: 0;
}

.dt-item-carousel.dt-style-02 {
  background-color: transparent;
  position: relative;
}

.dt-item-carousel.dt-style-02:before {
  content: '';
  background-color: #000;
  position: absolute;
  height: 177px;
  width: 100%;
  top: 25px;
  left: 0;
}

@media (max-width: 1024px) {
  .dt-item-carousel.dt-style-02:before {
    height: 167px;
  }
}

@media (max-width: 768px) {
  .dt-item-carousel.dt-style-02:before {
    top: 0px;
    height: 100%;
  }
}

.dt-item-carousel.dt-style-02 [data-swiper="prev"],
.dt-item-carousel.dt-style-02 [data-swiper="next"] {
  height: 75%;
  top: 50%;
  transform: translateY(-50%);
}

@media (min-width: 769px) {
  .dt-item-carousel.dt-style-02 > .swiper-container {
    padding: 25px 0;
  }
}

.dt-item-carousel.dt-style-02 .swiper-slide {
  transition: all 0.5s cubic-bezier(0.18, 0.89, 0.32, 1.28);
}

.dt-item-carousel.dt-style-02 .swiper-slide .dt-caption {
  padding: 0 15px;
  bottom: 15px;
}

.dt-item-carousel.dt-style-02 .swiper-slide .dt-caption .dt-title {
  padding: 20px;
}

@media (min-width: 769px) {
  .dt-item-carousel.dt-style-02 .swiper-slide.swiper-slide-active {
    transform: scale(1.2);
  }
}

.dt-item-carousel.dt-style-02 .swiper-slide.swiper-slide-active .dt-caption {
  transition-delay: 0.4s;
  opacity: 1;
  transform: translateY(0%);
}

.dt-item-carousel.dt-style-02 .swiper-slide.swiper-slide-active .dt-caption .dt-title {
  padding: 15px;
  font-size: 1.2em;
}

.dt-item-carousel.dt-style-02.gray:before {
  background-color: #ebeef3;
}

.dt-item-carousel.dt-style-02.gray [data-swiper="prev"] > i,
.dt-item-carousel.dt-style-02.gray [data-swiper="next"] > i {
  color: #000;
}

.dt-item-carousel.dt-style-02.gray [data-swiper="prev"].dt-with-title > .dt-title,
.dt-item-carousel.dt-style-02.gray [data-swiper="next"].dt-with-title > .dt-title {
  color: #000;
}

@media (max-width: 480px) {
  .dt-item-carousel.dt-style-02.gray [data-swiper="prev"].dt-with-title > .dt-title,
  .dt-item-carousel.dt-style-02.gray [data-swiper="next"].dt-with-title > .dt-title {
    display: none;
  }
}

.dt-item-carousel.dt-style-02.gray .dt-caption > .dt-title {
  border: 1px solid #000;
  background-color: rgba(255, 255, 255, 0.9);
  color: #000;
}

.dt-item-carousel.dt-style-03 {
  padding: 0 10%;
}

.dt-item-carousel.dt-style-03 [data-swiper="prev"],
.dt-item-carousel.dt-style-03 [data-swiper="next"] {
  width: 10%;
}

.dt-item-carousel.dt-style-03 [data-swiper="prev"] > i,
.dt-item-carousel.dt-style-03 [data-swiper="next"] > i {
  font-size: 40px;
}

.dt-item-carousel [data-click-to-slide="true"] .swiper-slide:hover {
  cursor: pointer;
}

.dt-posts-carousel {
  position: relative;
}

.dt-posts-carousel .swiper-slide {
  padding-right: 120px;
  padding-left: 120px;
}

@media (max-width: 480px) {
  .dt-posts-carousel .swiper-slide {
    padding: 25px;
  }
}

.dt-posts-carousel [data-swiper="pagination"] {
  text-align: center;
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  z-index: 100;
}

.dt-posts-carousel [data-swiper="pagination"] > span {
  width: 20px;
  height: 20px;
  border: 1px solid #000;
  opacity: 1;
  background-color: transparent;
  margin: 0 22px;
  transition: all 0.15s ease-in-out;
}

.dt-posts-carousel [data-swiper="pagination"] > span[class*="active"] {
  background-color: #000;
}

.dt-posts-carousel [data-swiper="next"],
.dt-posts-carousel [data-swiper="prev"] {
  position: absolute;
  top: 30%;
  font-size: 60px;
  color: #000;
  z-index: 100;
}

.dt-posts-carousel [data-swiper="next"]:hover,
.dt-posts-carousel [data-swiper="prev"]:hover {
  cursor: pointer;
}

.dt-posts-carousel [data-swiper="prev"] {
  left: 0;
}

.dt-posts-carousel [data-swiper="next"] {
  right: 0;
}

/*--------------------------------------------------------------
  ## Blog
  --------------------------------------------------------------*/
.dt-blog-entries:before, .dt-blog-entries:after {
  content: "";
  display: table;
}

.dt-blog-entries:after {
  clear: both;
}

@media (min-width: 1024px) {
  .dt-blog-entries[data-column="2"] .dt-post {
    display: inline-block;
    width: 50%;
    padding: 0 18px;
  }
  .dt-blog-entries[data-column="2"] .dt-post:nth-child(odd) {
    float: left;
    clear: left;
  }
  .dt-blog-entries[data-column="2"] .dt-post:nth-child(even) {
    clear: right;
  }
}

.dt-blog-entries.dt-full > .dt-post {
  padding-right: 115px;
  padding-bottom: 100px;
  padding-left: 115px;
  border-bottom: 1px solid #d0d4dd;
}

.dt-blog-entries.dt-full > .dt-post .dt-entry-featured {
  margin-right: -115px;
  margin-left: -115px;
}

.dt-blog-entries.dt-full > .dt-post:last-child {
  padding-bottom: 0;
  border-bottom: 0;
}

@media (max-width: 768px) {
  .dt-blog-entries.dt-full > .dt-post {
    padding-right: 30px;
    padding-left: 30px;
    padding-bottom: 30px;
  }
  .dt-blog-entries.dt-full > .dt-post .dt-entry-featured {
    margin-right: -30px;
    margin-left: -30px;
  }
}

.dt-featured-post {
  margin-bottom: 100px;
}

.dt-featured-post > .dt-post {
  margin-bottom: 0;
  padding-bottom: 100px;
  border-bottom: 1px solid #d0d4dd;
}

/*--------------------------------------------------------------
  ## Article
  --------------------------------------------------------------*/
.dt-post {
  margin-bottom: 100px;
}

.dt-post:before, .dt-post:after {
  content: "";
  display: table;
}

.dt-post:after {
  clear: both;
}

@media (max-width: 768px) {
  .dt-post {
    max-width: 460px;
    margin-right: auto;
    margin-left: auto;
    padding: 0 15px;
  }
  [class^="col-"] > .dt-post {
    padding: 0;
  }
}

.dt-post .dt-entry-header > .dt-title {
  margin-top: 0;
  margin-bottom: 15px;
  text-transform: uppercase;
  color: #000;
}

.dt-post .dt-entry-header > .dt-title > a {
  display: block;
  color: #000;
  margin-bottom: 5px;
}

.dt-post .dt-entry-header > .dt-title > small {
  display: block;
  font-size: 0.75em;
  text-transform: uppercase;
  color: #000;
}

.dt-post .dt-entry-header > .dt-entry-meta > ul {
  padding: 0;
}

.dt-post .dt-entry-header > .dt-entry-meta > ul > li {
  display: inline-block;
}

.dt-post .dt-entry-header > .dt-entry-meta > ul > li:after {
  content: '';
  display: inline-block;
  height: 12px;
  width: 1px;
  background-color: #000;
  margin-right: 4px;
  margin-left: 10px;
}

.dt-post .dt-entry-header > .dt-entry-meta > ul > li:last-child:after {
  display: none;
}

.dt-post .dt-entry-header > .dt-entry-featured {
  margin-bottom: 30px;
}

.dt-post .dt-entry-header > .dt-entry-featured > img {
  width: 100%;
  height: auto;
}

.dt-post .dt-entry-header > .dt-entry-featured.border-style {
  border: 1px solid #000;
  padding: 40px;
  overflow: hidden;
}

.dt-post .dt-entry-content {
  margin-bottom: 15px;
}

.dt-post .dt-entry-content > img {
  margin-right: 35px;
  margin-left: 35px;
  margin-bottom: 35px;
}

.dt-post .dt-entry-content > img.pull-left {
  margin-left: 0;
}

.dt-post .dt-entry-content .dt-image-carousel {
  margin: 50px 115px;
}

@media (max-width: 768px) {
  .dt-post .dt-entry-content .dt-image-carousel {
    margin: 50px 30px;
  }
}

.dt-post .dt-entry-content p,
.dt-post .dt-entry-content .dt-video-item {
  margin-bottom: 50px;
}

.dt-post .dt-entry-footer:before, .dt-post .dt-entry-footer:after {
  content: "";
  display: table;
}

.dt-post .dt-entry-footer:after {
  clear: both;
}

.dt-post .dt-entry-footer > .dt-entry-more {
  float: left;
  line-height: 1em;
}

.dt-post .dt-entry-footer > .dt-entry-category {
  line-height: 1em;
  float: right;
  text-align: right;
}

.dt-post.full-width .dt-video-item,
.dt-post.full-width .blockquote,
.dt-post.full-width blockquote {
  margin: 50px 60px;
}

@media (max-width: 768px) {
  .dt-post.full-width .dt-video-item,
  .dt-post.full-width .blockquote,
  .dt-post.full-width blockquote {
    margin: 50px 20px;
  }
}

.dt-post.full-width .dt-audio-stream {
  margin: 30px 175px;
}

@media (max-width: 768px) {
  .dt-post.full-width .dt-audio-stream {
    margin: 50px 20px;
  }
}

.dt-post.personal .dt-entry-content {
  margin-bottom: 15px;
}

.dt-post.personal .dt-entry-content .dt-image-carousel {
  margin: 50px 0px;
}

@media (max-width: 768px) {
  [data-isotope="container"] .dt-post {
    width: 100%;
    max-width: inherit;
  }
  [data-isotope="container"] .dt-post > .dt-entry-header,
  [data-isotope="container"] .dt-post > .dt-entry-content,
  [data-isotope="container"] .dt-post > .dt-entry-footer {
    max-width: 460px;
    margin: 0 auto;
  }
}

/*--------------------------------------------------------------
  ## Video
  --------------------------------------------------------------*/
.dt-videos-list[data-column="2"] > div {
  margin-bottom: 45px;
}

.dt-videos-list[data-column="2"] > div:nth-child(odd) {
  clear: both;
}

.dt-videos-list[data-column="2"] > div:nth-child(even) {
  clear: none;
}

.dt-video-item {
  margin-bottom: 25px;
}

@media (max-width: 1024px) {
  .dt-video-item {
    max-width: 460px;
    margin-right: auto;
    margin-left: auto;
  }
}

.dt-video-item:last-child {
  margin-bottom: 0;
}

.dt-video-item .dt-video-thumb {
  position: relative;
  overflow: hidden;
}

.dt-video-item .dt-video-thumb > img {
  width: 100%;
  height: auto;
}

.dt-video-item .dt-video-thumb > a {
  display: block;
  width: 75px;
  height: 45px;
  position: absolute;
  bottom: 0;
  left: 0;
  margin-bottom: 20px;
  margin-left: 20px;
  background-image: url(../img/play_icon.png);
  background-repeat: no-repeat;
}

.dt-video-item .dt-video-thumb > a:hover {
  opacity: 0.5;
}

.dt-video-item .dt-video-thumb > a > span {
  visibility: hidden;
}

.dt-video-item .dt-video-title {
  display: block;
  margin-top: 20px;
  padding-bottom: 15px;
  padding-right: 20px;
  padding-left: 20px;
  border-bottom: 1px solid #000;
  line-height: 1.3em;
  text-align: center;
  text-transform: uppercase;
  position: relative;
}

.dt-video-item .dt-video-title:before, .dt-video-item .dt-video-title:after {
  content: '';
  position: absolute;
  width: 1px;
  height: 70%;
  background-color: #000;
  bottom: 0;
}

.dt-video-item .dt-video-title:before {
  left: 0;
}

.dt-video-item .dt-video-title:after {
  right: 0;
}

.dt-video-item .dt-video-title > a {
  color: #000;
  text-decoration: none;
}

.dt-video-item .dt-video-description {
  margin-top: 25px;
}

/*--------------------------------------------------------------
  ## Gallery
  --------------------------------------------------------------*/
.dt-photo-gallery {
  margin: 0 auto;
}

.dt-gallery-item {
  position: relative;
  width: 18.86363636363636%;
  height: 500px;
  overflow: hidden;
}

@media (max-width: 1024px) {
  .dt-gallery-item {
    height: 290px;
  }
}

@media (max-width: 768px) {
  .dt-gallery-item {
    width: 49.9%;
    height: auto;
  }
}

.dt-gallery-item > img {
  width: 102%;
  height: auto;
  transition: all 0.5s ease-in-out;
}

.dt-gallery-item > .dt-item-info {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: transparent;
  transition: background 0.15s ease-in-out;
}

.dt-gallery-item > .dt-item-info > .dt-info-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #FFF;
  opacity: 0;
}

.dt-gallery-item > .dt-item-info > .dt-info-content > h3,
.dt-gallery-item > .dt-item-info > .dt-info-content > ul {
  transform: translateY(250%);
  opacity: 0;
  transition: all 0.25s cubic-bezier(0.18, 0.89, 0.32, 1.28);
}

.dt-gallery-item > .dt-item-info > .dt-info-content > ul {
  transition-delay: .05s;
}

.dt-gallery-item > .dt-item-info a {
  color: #FFF;
}

.dt-gallery-item:hover > img {
  transform: scale(1.1) rotate(2deg);
}

.dt-gallery-item:hover > .dt-item-info {
  background-color: rgba(0, 0, 0, 0.7);
}

.dt-gallery-item:hover > .dt-item-info > .dt-info-content {
  opacity: 1;
}

.dt-gallery-item:hover > .dt-item-info > .dt-info-content > h3,
.dt-gallery-item:hover > .dt-item-info > .dt-info-content > ul {
  opacity: 1;
  transform: translateY(0%);
}

.dt-gallery-item > .dt-gallery-title {
  position: absolute;
  width: 100%;
  height: 250px;
  background-color: transparent;
}

.dt-gallery-item > .dt-gallery-title > .dt-info-content {
  opacity: 1;
}

.dt-gallery-item > .dt-gallery-title > .dt-info-content > h3,
.dt-gallery-item > .dt-gallery-title > .dt-info-content > ul {
  transform: translate(0);
  opacity: 1;
}

.dt-gallery-item > .dt-gallery-title > .dt-info-content a {
  color: #395687;
}

.dt-gallery-item > .dt-gallery-title:hover {
  background-color: transparent;
}

.dt-gallery-item > .dt-gallery-title:hover > .dt-info-content {
  opacity: 1;
}

.dt-gallery-item > .dt-gallery-title.style-2 {
  background-color: #f8f9fb;
}

.dt-gallery-item > .dt-gallery-title.style-2:before {
  content: '';
  position: absolute;
  top: 35px;
  right: 35px;
  bottom: 35px;
  left: 35px;
  border: 1px solid #000;
}

.dt-gallery-item > .dt-gallery-title.style-2 > .dt-info-content {
  width: 100%;
  color: #000;
}

.dt-gallery-item.size-2 {
  width: 37.72727272727273%;
}

@media (max-width: 768px) {
  .dt-gallery-item.size-2 {
    width: 49.9%;
    height: auto;
  }
}

.dt-gallery-item.size-3 {
  width: 24.43181818181818%;
  height: 250px;
}

@media (max-width: 1024px) {
  .dt-gallery-item.size-3 {
    height: 145px;
  }
}

@media (max-width: 768px) {
  .dt-gallery-item.size-3 {
    width: 49.9%;
    height: auto;
  }
}

.dt-gallery-item.size-4 {
  width: 24.43181818181818%;
  height: 500px;
}

@media (max-width: 1024px) {
  .dt-gallery-item.size-4 {
    height: 290px;
  }
}

@media (max-width: 768px) {
  .dt-gallery-item.size-4 {
    width: 49.9%;
    height: auto;
  }
}

.dt-gallery-item.size-5 {
  width: 37.72727272727273%;
  height: 1000px;
}

@media (max-width: 1024px) {
  .dt-gallery-item.size-5 {
    height: 590px;
  }
}

@media (max-width: 768px) {
  .dt-gallery-item.size-5 {
    width: 49.9%;
    min-height: 125px;
  }
}

.dt-gallery-item.size-6 {
  width: 24.43181818181818%;
  height: 500px;
}

@media (max-width: 1024px) {
  .dt-gallery-item.size-6 {
    height: 290px;
  }
}

@media (max-width: 768px) {
  .dt-gallery-item.size-6 {
    width: 49.9%;
    height: auto;
  }
}

.dt-gallery-item.dt-title-item {
  height: 250px;
}

/*--------------------------------------------------------------
  ## Author Profile
  --------------------------------------------------------------*/
.dt-author-profile:before, .dt-author-profile:after {
  content: "";
  display: table;
}

.dt-author-profile:after {
  clear: both;
}

.dt-author-profile > .dt-profile-img {
  width: 315px;
  height: 315px;
  overflow: hidden;
  float: left;
}

@media (max-width: 768px) {
  .dt-author-profile > .dt-profile-img {
    float: none;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 40px;
  }
}

.dt-author-profile > .dt-profile-img > img {
  width: 100%;
  height: auto;
}

.dt-author-profile > .dt-profile-info {
  padding-left: 350px;
}

@media (max-width: 768px) {
  .dt-author-profile > .dt-profile-info {
    padding: 0;
  }
}

.dt-author-profile > .dt-profile-info > .dt-author-name {
  font-weight: bold;
  color: #000;
  margin-top: 0;
  margin-bottom: 20px;
  line-height: 1em;
}

.dt-author-profile > .dt-profile-info > .dt-author-job-and-link {
  overflow: hidden;
  margin-bottom: 20px;
  line-height: 1em;
}

.dt-author-profile > .dt-profile-info > .dt-author-job-and-link > .dt-name {
  float: left;
}

.dt-author-profile > .dt-profile-info > .dt-author-job-and-link > .dt-link {
  float: right;
}

.dt-author-profile > .dt-profile-info > .dt-social-icons {
  margin-bottom: 30px;
  line-height: 1em;
}

.dt-author-profile > .dt-profile-info > .dt-social-icons > li {
  line-height: 1em;
}

.dt-author-profile > .dt-profile-info > .dt-author-bio {
  padding: 47px 100px;
  border: 1px solid #000;
  position: relative;
  font-size: 1.111111111111111em;
  font-style: italic;
  margin-bottom: 45px;
}

@media (max-width: 768px) {
  .dt-author-profile > .dt-profile-info > .dt-author-bio {
    padding: 20px 25px;
  }
}

.dt-author-profile > .dt-profile-info > .dt-author-bio:before {
  content: "\2019 \2019";
  font-family: sans-serif;
  position: absolute;
  top: 20px;
  left: 32px;
  font-size: 80px;
  line-height: 1em;
  font-weight: bold;
  font-style: normal;
  color: #000;
}

@media (max-width: 768px) {
  .dt-author-profile > .dt-profile-info > .dt-author-bio:before {
    top: 10px;
    left: 10px;
    font-size: 40px;
  }
}

.dt-author-profile > .dt-profile-info p {
  margin-bottom: 50px;
}

.dt-author-profile > .dt-profile-info .dt-video-item {
  margin-left: 120px;
  margin-right: 120px;
}

/*--------------------------------------------------------------
  ## Breadcrumbs
  --------------------------------------------------------------*/
.dt-breadcrumbs {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}

.dt-breadcrumbs:before, .dt-breadcrumbs:after {
  content: "";
  display: table;
}

.dt-breadcrumbs:after {
  clear: both;
}

.dt-breadcrumbs > li {
  display: inline-block;
  position: relative;
}

.dt-breadcrumbs > li > a {
  color: #000;
}

.dt-breadcrumbs > li:after {
  content: '/';
  padding-left: 5px;
  display: inline-block;
}

.dt-breadcrumbs > li:last-child:after {
  display: none;
}

/*--------------------------------------------------------------
  ## Page header
  --------------------------------------------------------------*/
.dt-page-header {
  padding: 130px 0;
  background-position: center center;
  background-size: cover;
  overflow: hidden;
}

@media (max-width: 768px) {
  .dt-page-header {
    padding: 30px 0;
  }
}

.dt-page-header .dt-title {
  margin: 0;
  background-color: rgba(0, 0, 0, 0.7);
  color: #FFF;
  padding: 45px;
  font-weight: normal;
  font-size: 3.333333333333333em;
  line-height: 1em;
  position: relative;
}

@media (max-width: 768px) {
  .dt-page-header .dt-title {
    padding: 20px;
    font-size: 2em;
  }
}

.dt-page-header .dt-title:before, .dt-page-header .dt-title:after {
  content: '';
  position: absolute;
  height: 100%;
  width: 500%;
  background-color: rgba(0, 0, 0, 0.7);
  top: 0;
}

.dt-page-header .dt-title:before {
  left: -500%;
}

.dt-page-header .dt-title:after {
  right: -500%;
}

.dt-page-header.dt-right-text .dt-title {
  padding: 45px 0px 45px 100px;
  float: right;
}

@media (max-width: 768px) {
  .dt-page-header.dt-right-text .dt-title {
    padding: 20px 0px 20px 50px;
  }
}

.dt-page-header.dt-right-text .dt-title:before {
  display: none;
}

.dt-page-header.dt-alt-color .dt-title {
  background-color: rgba(57, 86, 135, 0.1);
  color: #000;
}

.dt-page-header.dt-alt-color .dt-title:before, .dt-page-header.dt-alt-color .dt-title:after {
  background-color: rgba(57, 86, 135, 0.1);
}

/*--------------------------------------------------------------
  ## Pagination
  --------------------------------------------------------------*/
.dt-pagination-container {
  border-top: 1px solid #cccccc;
  border-bottom: 1px solid #cccccc;
  padding: 20px 0;
}

.dt-pagination {
  padding-left: 0;
  margin-bottom: 0;
}

.dt-pagination > li {
  display: inline-block;
  line-height: 1em;
  margin: 0 8px;
  border: 1px solid #000;
  transition: all 0.15s ease-in-out;
}

.dt-pagination > li:first-child {
  margin-left: 0;
}

.dt-pagination > li > a {
  display: block;
  padding: 5px 10px;
  color: #000;
  position: relative;
  border-bottom: 1px solid transparent;
}

.dt-pagination > li > a:before, .dt-pagination > li > a:after {
  content: '';
  position: absolute;
  width: 1px;
  height: 100%;
  bottom: 0;
  background-color: transparent;
  transition: all 0.15s ease-in-out;
  transition-delay: 0.15s;
}

.dt-pagination > li > a:before {
  left: 0px;
}

.dt-pagination > li > a:after {
  right: 0px;
}

.dt-pagination > li:hover, .dt-pagination > li.active {
  border-color: transparent;
}

.dt-pagination > li:hover > a, .dt-pagination > li.active > a {
  border-bottom-color: #000;
}

.dt-pagination > li:hover > a:before, .dt-pagination > li:hover > a:after, .dt-pagination > li.active > a:before, .dt-pagination > li.active > a:after {
  height: 50%;
  background-color: #000;
}

/*--------------------------------------------------------------
  ## Category List
  --------------------------------------------------------------*/
.dt-categories-list {
  padding-left: 0;
  margin-bottom: 0;
}

.dt-categories-list > li {
  list-style: none;
  margin-bottom: 20px;
}

.dt-categories-list > li > a {
  padding-left: 15px;
  display: block;
}

/*--------------------------------------------------------------
  ## Blockquote
  --------------------------------------------------------------*/
blockquote,
.blockquote {
  padding: 40px 100px;
  margin-bottom: 50px;
  border: 1px solid #000;
  position: relative;
  font-size: 1.111111111111111em;
  font-style: italic;
}

blockquote:before,
.blockquote:before {
  content: "\2019 \2019";
  font-family: sans-serif;
  position: absolute;
  top: 20px;
  left: 32px;
  font-size: 80px;
  line-height: 1em;
  font-weight: bold;
  font-style: normal;
  color: #000;
}

@media (max-width: 768px) {
  blockquote,
  .blockquote {
    padding: 20px 30px;
  }
  blockquote:before,
  .blockquote:before {
    font-size: 40px;
    top: 10px;
    left: 10px;
  }
}

/*--------------------------------------------------------------
  ## Share
  --------------------------------------------------------------*/
.dt-share {
  padding: 0;
  line-height: 1em;
}

.dt-share > li {
  display: inline-block;
  font-size: 0.7777777777777778em;
  line-height: 1em;
  margin-right: 10px;
}

.dt-share > li:last-child {
  margin-right: 0;
}

.dt-share > li.share-text {
  text-transform: uppercase;
}

.dt-share > li > a {
  color: #555555;
}

/*--------------------------------------------------------------
  ## Comment Action
  --------------------------------------------------------------*/
.dt-comment-action {
  margin: 0;
  padding: 0;
  line-height: 1em;
}

.dt-comment-action > li {
  display: inline-block;
  margin-right: 25px;
}

.dt-comment-action > li:last-child {
  margin-right: 0;
}

.dt-comment-action > li > a {
  font-size: 0.7777777777777778em;
  color: #555555;
}

.dt-comment-action > li > a > i {
  margin-right: 5px;
}

.dt-comment-action > li > a > span {
  text-transform: uppercase;
}

.dt-comment-action > li > a:hover {
  color: #395687;
}

/*--------------------------------------------------------------
  ## Comment Form
  --------------------------------------------------------------*/
#respond > .dt-title {
  font-size: 1em;
  padding: 10px 0;
  margin-bottom: 50px;
  color: #000;
}

#commentform > .comment-form-comment {
  margin-bottom: 25px;
}

#commentform > .comment-form-comment > label {
  color: #000;
  font-weight: normal;
  margin-bottom: 0;
}

#commentform > .comment-form-comment > textarea {
  width: 100%;
  height: 100px;
  border: 1px solid #000;
  border-top: 0px;
  padding: 10px;
}

#commentform > .comment-form-comment > textarea:focus {
  outline-color: transparent;
}

#commentform > .comment-form-author,
#commentform > .comment-form-email {
  margin-bottom: 30px;
  width: 50%;
  float: left;
}

#commentform > .comment-form-author > label,
#commentform > .comment-form-email > label {
  color: #000;
  font-weight: normal;
  margin-bottom: 0;
}

#commentform > .comment-form-author > input,
#commentform > .comment-form-email > input {
  display: block;
  width: 100%;
  border: 1px solid #000;
  border-top: none;
  padding: 0 10px;
}

#commentform > .comment-form-author > input:focus,
#commentform > .comment-form-email > input:focus {
  outline-color: transparent;
}

#commentform > .comment-form-email {
  padding-left: 20px;
}

#commentform > .comment-form-author {
  padding-right: 20px;
}

#commentform > .form-submit {
  width: 50%;
  padding-right: 20px;
}

#commentform > .form-submit > input {
  display: block;
  width: 100%;
  background-color: #000;
  color: #FFF;
  border: 1px solid #000;
  padding: 12px 20px;
  line-height: 1em;
  transition: all 0.15s ease-in-out;
}

#commentform > .form-submit > input:hover {
  color: #000;
  border-top-color: transparent;
  background-color: transparent;
}

/*--------------------------------------------------------------
  ## Audio Player
  --------------------------------------------------------------*/
.dt-audio-stream {
  position: relative;
  border: 1px solid #dad7d7;
  padding: 40px;
  margin-bottom: 30px;
}

@media (max-width: 768px) {
  .dt-audio-stream {
    padding: 25px 15px;
  }
}

.dt-audio-stream:hover {
  background-color: #f2f2f2;
}

.dt-audio-stream > .dt-media-thumb {
  width: 120px;
  height: 90px;
  overflow: hidden;
  float: left;
}

@media (max-width: 768px) {
  .dt-audio-stream > .dt-media-thumb {
    float: none;
    margin: 0 auto 25px auto;
  }
}

.dt-audio-stream > .dt-media-thumb > img {
  width: 100%;
  height: auto;
}

.dt-audio-stream > .dt-media-content {
  padding-left: 165px;
  position: relative;
}

@media (max-width: 768px) {
  .dt-audio-stream > .dt-media-content {
    padding-left: 0;
  }
}

.dt-audio-stream > .dt-media-content > .dt-title {
  margin-top: 0;
  margin-bottom: 2px;
  font-weight: bold;
  text-transform: uppercase;
  color: #000;
}

.dt-audio-stream > .dt-media-content > audio {
  width: 100%;
}

/*--------------------------------------------------------------
  ## Gallery Info
  --------------------------------------------------------------*/
.dt-gallery-and-info > div > .dt-image-carousel {
  margin-bottom: 50px;
}

.dt-gallery-and-info > .dt-center-div > .dt-gallery-info > .content {
  margin-bottom: 50px;
}

.dt-gallery-and-info > .dt-center-div > .dt-gallery-info > table.meta {
  width: 100%;
}

.dt-gallery-and-info > .dt-center-div > .dt-gallery-info > table.meta tr th {
  padding-right: 17.5px;
  text-align: right;
  font-weight: bold;
  text-transform: uppercase;
  padding-bottom: 20px;
}

.dt-gallery-and-info > .dt-center-div > .dt-gallery-info > table.meta tr td {
  padding-left: 17.5px;
  padding-bottom: 20px;
}

.dt-gallery-and-info > .dt-center-div > .dt-gallery-info > table.meta tr td > a {
  text-decoration: underline;
}

@media (min-width: 768px) {
  .dt-gallery-and-info {
    display: flex;
  }
  .dt-gallery-and-info .dt-center-div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

/*--------------------------------------------------------------
  ## Content Block
  --------------------------------------------------------------*/
.dt-content-block {
  margin-bottom: 115px;
}

@media (min-width: 769px) {
  .dt-content-block > .row {
    display: flex;
  }
}

@media (min-width: 769px) {
  .dt-content-block > .row > .content {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.dt-content-block > .row > .content > .inside > .dt-title {
  color: #000;
  margin-bottom: 20px;
}

.dt-content-block > .row > .graphic {
  z-index: 100;
}

.dt-content-block > .row > .graphic > img {
  width: 100%;
  height: auto;
}

.dt-content-block.left-graphic .row > .content > .inside {
  z-index: 101;
  border: 1px solid #000;
  padding: 65px 90px;
  margin-bottom: 25px;
}

@media (min-width: 769px) {
  .dt-content-block.left-graphic .row > .content > .inside {
    margin-bottom: 0px;
    transform: translateX(50px);
  }
}

/*--------------------------------------------------------------
  ## Project
  --------------------------------------------------------------*/
.dt-project {
  padding-bottom: 50px;
  margin-bottom: 50px;
  border-bottom: 1px solid #cccccc;
}

.dt-project:before, .dt-project:after {
  content: "";
  display: table;
}

.dt-project:after {
  clear: both;
}

.dt-project:last-child {
  border-bottom: none;
  margin-bottom: 0;
}

.dt-project > .dt-project-featured {
  width: 315px;
  float: left;
}

.dt-project > .dt-project-featured > img {
  width: 100%;
  height: auto;
}

.dt-project > .dt-project-header,
.dt-project > .dt-project-content,
.dt-project > .dt-project-footer {
  padding-left: 350px;
}

.dt-project > .dt-project-header > .dt-title {
  margin-top: 0;
  margin-bottom: 30px;
}

.dt-project > .dt-project-header > .dt-title > a {
  display: block;
  color: #000;
}

.dt-project > .dt-project-header > .dt-title > small {
  color: #000;
}

.dt-project > .dt-project-header > .dt-project-meta > ul {
  padding: 0;
  margin-bottom: 20px;
}

.dt-project > .dt-project-header > .dt-project-meta > ul > li {
  list-style: none;
  color: #000;
}

.dt-project > .dt-project-header > .dt-project-meta > ul > li > span {
  font-weight: bold;
  text-transform: uppercase;
}

.dt-project > .dt-project-content {
  margin-bottom: 25px;
}

.dt-project > .dt-project-content > a {
  text-decoration: underline;
}

.dt-project > .dt-project-footer > .dt-project-meta > ul {
  padding: 0;
  margin: 0;
}

.dt-project > .dt-project-footer > .dt-project-meta > ul > li {
  list-style: none;
}

.dt-project > .dt-project-footer > .dt-project-meta > ul > li > span {
  font-weight: bold;
  text-transform: uppercase;
}

.dt-project > .dt-project-footer > .dt-project-meta > ul > li > a {
  text-decoration: underline;
}

/*--------------------------------------------------------------
  ## Contact Info
  --------------------------------------------------------------*/
.dt-contacts-list {
  padding: 0;
}

.dt-contacts-list > li {
  list-style: none;
  color: #000;
  margin-bottom: 10px;
}

.dt-contacts-list > li > a {
  text-decoration: underline;
}

/*--------------------------------------------------------------
  ## Contact form
  --------------------------------------------------------------*/
.dt-contact-form label {
  font-weight: normal;
  color: #000;
  margin-bottom: 0;
}

.dt-contact-form p {
  margin-bottom: 25px;
}

.dt-contact-form input,
.dt-contact-form textarea {
  display: block;
  width: 100%;
  border: 1px solid #000;
  border-top: none;
  padding: 0px 10px;
}

.dt-contact-form input:focus, .dt-contact-form input:active,
.dt-contact-form textarea:focus,
.dt-contact-form textarea:active {
  outline-color: transparent;
}

.dt-contact-form input[type="submit"],
.dt-contact-form textarea[type="submit"] {
  background-color: #000;
  color: #FFF;
  padding-top: 7px;
  padding-bottom: 7px;
  transition: all 0.15s ease-in-out;
}

.dt-contact-form input[type="submit"]:hover,
.dt-contact-form textarea[type="submit"]:hover {
  background-color: transparent;
  color: #000;
}

/*--------------------------------------------------------------
  ## Countdown timer
  --------------------------------------------------------------*/
.dt-countdown-timer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dt-countdown-timer > div {
  padding: 0 40px;
  font-size: 1.333333333333333em;
}

.dt-countdown-timer > div > span {
  padding-left: 10px;
  padding-right: 10px;
  display: block;
  border-bottom: 1px solid #000;
  position: relative;
  font-size: 2em;
  line-height: 1em;
  margin-bottom: 5px;
}

.dt-countdown-timer > div > span:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  height: 50%;
  width: 1px;
  background-color: #000;
}

.dt-countdown-timer > div > span:after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  width: 1px;
  height: 50%;
  background-color: #000;
}

/*--------------------------------------------------------------
  ## Masonry Blog Grid
  --------------------------------------------------------------*/
.dt-masonry-blog-grid {
  margin-left: -20px;
  margin-right: -20px;
}

.dt-masonry-blog-grid > .dt-gallery-item {
  width: auto;
  height: auto;
  padding: 20px;
}

.dt-masonry-blog-grid > .dt-gallery-item > .dt-item-info {
  position: relative;
}

.dt-masonry-blog-grid > .dt-gallery-item > .dt-item-info:before {
  content: '';
  background-color: rgba(255, 255, 255, 0.7);
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0%;
  left: 0;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.15s ease-in-out;
}

.dt-masonry-blog-grid > .dt-gallery-item > .dt-item-info:after {
  content: '';
  position: absolute;
  top: 40px;
  right: 40px;
  bottom: 40px;
  left: 40px;
  border: 1px solid #000;
  z-index: 2;
  opacity: 0;
  transition: opacity 0.15s ease-in-out;
}

.dt-masonry-blog-grid > .dt-gallery-item > .dt-item-info > .dt-info-content {
  text-align: center;
  z-index: 3;
  color: #000;
}

.dt-masonry-blog-grid > .dt-gallery-item > .dt-item-info > .dt-info-content a {
  color: #000;
}

.dt-masonry-blog-grid > .dt-gallery-item:hover > .dt-item-info:before,
.dt-masonry-blog-grid > .dt-gallery-item:hover > .dt-item-info:after {
  opacity: 1;
}

/*--------------------------------------------------------------
  # Sections
  --------------------------------------------------------------*/
/*--------------------------------------------------------------
  ## Header
  --------------------------------------------------------------*/
#dt-site-header.sliding-menu-enabled {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 901;
  transition: all 0.7s cubic-bezier(0.68, -0.55, 0.27, 1.55);
}

#dt-site-header.sliding-menu-enabled #dt-logo-and-social {
  width: 80px;
  height: 100%;
  padding-top: 50px;
  padding-bottom: 75px;
  background-color: #FFF;
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.09);
}

@media (max-width: 1024px) {
  #dt-site-header.sliding-menu-enabled #dt-logo-and-social {
    transition: all 0.25s ease-in-out;
  }
}

#dt-site-header.sliding-menu-enabled #dt-menu-trigger {
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 50px;
}

@media (max-width: 1024px) {
  #dt-site-header.sliding-menu-enabled #dt-menu-trigger {
    transform: translateX(80px);
  }
}

#dt-site-header.sliding-menu-enabled #dt-logo > a {
  display: block;
  text-align: center;
}

#dt-site-header.sliding-menu-enabled .dt-social-icons {
  position: absolute;
  left: 0;
  bottom: 70px;
  right: 0;
}

#dt-site-header.sliding-menu-enabled #dt-site-menu {
  position: absolute;
  padding: 50px 80px;
  top: 0;
  left: -395px;
  bottom: 0;
  width: 395px;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
}

#dt-site-header.sliding-menu-enabled #dt-site-menu > div > ul {
  list-style: none;
  padding-left: 0;
}

#dt-site-header.sliding-menu-enabled #dt-site-menu > div > ul > li {
  margin-bottom: 30px;
}

#dt-site-header.sliding-menu-enabled #dt-site-menu > div > ul > li a {
  color: #FFF;
  font-size: 1.3333em;
  line-height: 1.3em;
  text-transform: uppercase;
}

#dt-site-header.sliding-menu-enabled #dt-site-menu > div > ul > li a:hover {
  border-bottom: 2px solid #FFF;
  text-decoration: none;
}

#dt-site-header.sliding-menu-enabled #dt-site-menu > div > ul > li a:focus {
  text-decoration: none;
}

#dt-site-header.sliding-menu-enabled #dt-site-menu > div > ul > li.current-menu-item > a {
  font-weight: bold;
  border-bottom: 2px solid #FFF;
}

#dt-site-header.sliding-menu-enabled #dt-site-menu > div > ul > li > ul {
  padding-left: 10px;
  padding-top: 25px;
  list-style: none;
  display: none;
}

#dt-site-header.sliding-menu-enabled #dt-site-menu > div > ul > li > ul > li > a {
  font-size: 1em;
  line-height: 1.8em;
  text-transform: inherit;
}

#dt-site-header.sliding-menu-enabled #dt-site-menu > div > ul > li.menu-item-has-children > a:after {
  content: "+";
  margin-left: 5px;
}

#dt-site-header.sliding-menu-enabled #dt-site-menu > div > ul > li.submenu-active > a:after {
  content: "-";
}

#dt-site-header.sliding-menu-enabled #dt-site-menu > div > ul > li.submenu-active > ul {
  display: block;
}

#dt-site-header.sliding-menu-enabled #dt-search-and-links {
  position: absolute;
  bottom: 70px;
  left: 80px;
  right: 80px;
}

#dt-site-header.sliding-menu-enabled #dt-search-and-links .dt-search-form {
  position: relative;
  height: 32px;
  margin-bottom: 40px;
}

#dt-site-header.sliding-menu-enabled #dt-search-and-links .dt-search-form > input {
  position: absolute;
  width: 100%;
  height: 100%;
  border: none;
  padding-right: 30px;
  padding-left: 10px;
}

#dt-site-header.sliding-menu-enabled #dt-search-and-links .dt-search-form button {
  position: absolute;
  border: none;
  background-color: transparent;
  top: 50%;
  right: 5px;
  transform: translateY(-50%);
}

#dt-site-header.sliding-menu-enabled #dt-search-and-links .dt-quick-links {
  padding: 0;
  text-align: center;
}

#dt-site-header.sliding-menu-enabled #dt-search-and-links .dt-quick-links > li {
  display: inline-block;
  margin-bottom: 0;
  line-height: 1em;
  border-right: 2px solid #FFF;
  padding-right: 10px;
  padding-left: 5px;
}

#dt-site-header.sliding-menu-enabled #dt-search-and-links .dt-quick-links > li > a {
  font-size: 1em;
}

#dt-site-header.sliding-menu-enabled #dt-search-and-links .dt-quick-links > li > a:hover {
  font-weight: normal;
}

#dt-site-header.sliding-menu-enabled #dt-search-and-links .dt-quick-links > li:first-child {
  padding-left: 0;
}

#dt-site-header.sliding-menu-enabled #dt-search-and-links .dt-quick-links > li:last-child {
  border-right: none;
  padding-right: 0;
}

.dt-menu-open #dt-site-header.sliding-menu-enabled {
  transform: translateX(395px);
}

@media (max-width: 1024px) {
  .dt-menu-open #dt-site-header.sliding-menu-enabled #dt-logo-and-social,
  .dt-menu-open #dt-site-header.sliding-menu-enabled #dt-menu-trigger {
    transform: translateX(0px);
  }
}

@media (max-width: 768px) {
  #dt-site-header {
    width: 100%;
  }
  #dt-site-header.sliding-menu-enabled {
    left: -100%;
    transition: all 0.7s ease-in-out;
  }
  #dt-site-header.sliding-menu-enabled #dt-logo-and-social {
    position: absolute;
    z-index: 101;
    right: 0;
  }
  #dt-site-header.sliding-menu-enabled #dt-site-menu {
    position: relative;
    left: auto;
    top: auto;
    width: 100%;
    height: 100%;
    overflow: scroll;
    z-index: 100;
    opacity: 0;
    padding-right: 160px;
    transition: opacity 0.7s ease-in-out;
  }
  #dt-site-header.sliding-menu-enabled #dt-search-and-links {
    position: relative;
    right: auto;
    bottom: auto;
    left: auto;
  }
  .dt-menu-open {
    transform: none;
  }
  .dt-menu-open #dt-site-header.sliding-menu-enabled {
    left: 0;
    margin-left: 0;
    transform: none;
  }
  .dt-menu-open #dt-site-header.sliding-menu-enabled #dt-site-menu {
    opacity: 1;
  }
}

@media (max-width: 375px) {
  #dt-site-header.sliding-menu-enabled #dt-site-menu {
    padding-top: 30px;
    padding-right: 100px;
    padding-bottom: 30px;
    padding-left: 30px;
  }
}

/*--------------------------------------------------------------
  ## Banner
  --------------------------------------------------------------*/
.dt-main-slider {
  position: relative;
}

@media (max-width: 1024px) {
  .dt-main-slider {
    padding-right: 40px;
  }
}

.dt-main-slider [data-swiper="pagination"] {
  position: absolute;
  top: 50%;
  right: -80px;
  width: 80px;
  z-index: 100;
  transform: translateY(-50%);
}

@media (max-width: 1024px) {
  .dt-main-slider [data-swiper="pagination"] {
    width: 40px;
    right: 0px;
  }
}

.dt-main-slider [data-swiper="pagination"] > span {
  width: 22px;
  height: 22px;
  display: block;
  background-color: transparent;
  border: 1px solid #000;
  opacity: 1;
  margin: 0 auto;
  margin-bottom: 45px;
  transition: all 0.15s ease-in-out;
}

@media (max-width: 768px) {
  .dt-main-slider [data-swiper="pagination"] > span {
    width: 15px;
    height: 15px;
    margin-bottom: 25px;
  }
}

.dt-main-slider [data-swiper="pagination"] > span:hover, .dt-main-slider [data-swiper="pagination"] > span[class*="active"] {
  background-color: #000;
}

.dt-main-slider [data-swiper="pagination"] > span:last-child {
  margin-bottom: 0;
}

.dt-main-slider [data-swiper="pagination"].left {
  right: auto;
  left: -80px;
}

.dt-main-slider .dt-section-switch {
  position: absolute;
  bottom: 45px;
  left: 50%;
  transform: translateX(-50%);
}

@media (max-width: 768px) {
  .dt-main-slider .dt-section-switch {
    bottom: 5px;
  }
  .dt-main-slider .dt-section-switch > .dt-angle-shape {
    width: 30px;
    height: 17.5px;
    background-size: 100%;
  }
}

/*--------------------------------------------------------------
  ## Hot Photos
  --------------------------------------------------------------*/
/*--------------------------------------------------------------
  ## Popular Article Now
  --------------------------------------------------------------*/
.dt-posts-list[data-column="2"] > div:nth-child(odd) {
  clear: both;
}

.dt-posts-list[data-column="2"] > div:nth-child(even) {
  clear: none;
}

/*--------------------------------------------------------------
  ## Footer
  --------------------------------------------------------------*/
.dt-site-footer {
  border-top: 1px solid #000;
  padding-top: 50px;
}

.dt-site-footer .dt-copyrigts-info {
  padding: 30px 0;
  border-top: 1px solid #e1e1e1;
}

.dt-site-footer .dt-back-to-top {
  display: table;
  width: 100px;
  margin: 0 auto 25px auto;
  position: relative;
}

.dt-site-footer .dt-back-to-top:before {
  content: '';
  position: absolute;
  width: 30px;
  height: 20px;
  left: 50%;
  margin-left: -15px;
  top: -25px;
  background-image: url(../img/arrow-up.png);
  background-repeat: no-repeat;
  background-position: center center;
}

.dt-site-footer.footer-personal {
  background-color: #ebeef3;
  border-top: none;
  padding-top: 100px;
}

.dt-site-footer.footer-personal .dt-copyrigts-info {
  border-top: none;
}

.dt-site-footer.footer-personal .widget > .widget-title {
  border: none;
  margin-bottom: 25px;
  padding: 0;
}

.dt-site-footer.footer-personal .footer-copyright-area {
  background-color: #FFF;
}

@media (max-width: 768px) {
  .dt-site-footer .widget {
    margin-bottom: 25px !important;
    border-bottom: 1px solid #e1e1e1;
    padding-bottom: 25px;
  }
  .dt-site-footer .widget.widget_dt_links .dt-links {
    text-align: center;
  }
  .dt-site-footer .dt-copyrigts-info > div, .dt-site-footer .dt-copyrigts-info ul {
    float: none !important;
    text-align: center;
    margin-bottom: 25px;
  }
  .dt-site-footer .last-widget .widget {
    border-bottom: 0;
  }
}

/*--------------------------------------------------------------
  ## Comments
  --------------------------------------------------------------*/
.dt-comments-section > .dt-title {
  margin-bottom: 50px;
  color: #000;
  padding: 15px 0;
}

#comments > .comment-list {
  list-style: none;
  padding: 0;
}

#comments > .comment-list li.comment.bypostauthor .dt-author-photo:after {
  content: 'Editor';
  font-size: 0.6666666666666667em;
  display: block;
  border: 1px solid #000;
  border-top: none;
  text-align: center;
  line-height: 1.6em;
}

#comments > .comment-list li.comment > .dt-comment-body {
  margin-bottom: 50px;
}

#comments > .comment-list li.comment > .dt-comment-body > .dt-author-photo {
  width: 64px;
  height: 64px;
  float: left;
}

#comments > .comment-list li.comment > .dt-comment-body > .dt-author-photo > img {
  width: 100%;
  height: 100%;
}

#comments > .comment-list li.comment > .dt-comment-body > .dt-comment-content {
  padding-bottom: 50px;
  border-bottom: 1px solid #cccccc;
  padding-left: 80px;
}

#comments > .comment-list li.comment > .dt-comment-body > .dt-comment-content > .dt-comment-meta {
  margin-bottom: 15px;
}

#comments > .comment-list li.comment > .dt-comment-body > .dt-comment-content > .dt-comment-meta > .dt-comment-author {
  font-weight: bold;
  text-transform: uppercase;
  color: #000;
  margin-top: 0;
  margin-bottom: 10px;
  line-height: 1em;
}

#comments > .comment-list li.comment > .dt-comment-body > .dt-comment-content > .dt-comment-meta > .dt-comment-author > a {
  color: #000;
}

#comments > .comment-list li.comment > .dt-comment-body > .dt-comment-content > .dt-comment-meta > .dt-comment-info {
  line-height: 1em;
}

#comments > .comment-list li.comment > .dt-comment-body > .dt-comment-content > .dt-comment-meta > .dt-comment-info > .dt-comment-date {
  font-size: 0.7777777777777778em;
  line-height: 1em;
}

#comments > .comment-list li.comment > .dt-comment-body > .dt-comment-content > .dt-content {
  margin-bottom: 15px;
}

#comments > .comment-list li.comment > .children {
  list-style: none;
  padding-left: 80px;
}

#comments > .comment-list li.comment:last-child li.comment:last-child > .dt-comment-body > .dt-comment-content {
  border-bottom: none;
}

/*--------------------------------------------------------------
  ## Contacts
  --------------------------------------------------------------*/
.dt-contact-details .gmap3-area {
  margin-bottom: 50px;
}

/*--------------------------------------------------------------
  ## Offline
  --------------------------------------------------------------*/
.offline-page {
  margin: 0px;
}

.dt-offline-section {
  height: 100vh;
  overflow: hidden;
}

.dt-offline-section:before, .dt-offline-section:after {
  content: "";
  display: table;
}

.dt-offline-section:after {
  clear: both;
}

.dt-offline-section > div {
  height: 100%;
  position: relative;
}

.dt-offline-section > div.dt-offline-content {
  width: 40%;
  float: left;
  text-align: right;
  padding-top: 10vh;
  padding-right: 25px;
}

.dt-offline-section > div.dt-offline-content > img {
  position: absolute;
  bottom: 50px;
  right: 50px;
  width: 10vw;
}

.dt-offline-section > div.dt-offline-content > .subtitle {
  text-transform: uppercase;
  color: #000;
  font-size: 1.0vw;
  margin-bottom: 0;
}

.dt-offline-section > div.dt-offline-content > .title {
  text-transform: uppercase;
  color: #000;
  font-size: 3.0vw;
  margin-top: 0;
}

.dt-offline-section > div.dt-offline-graphic {
  width: 60%;
  float: left;
  overflow: hidden;
}

.dt-offline-section > div.dt-offline-graphic > img {
  height: 100%;
  width: auto;
}

.dt-offline-section > div.dt-offline-graphic > .dt-social-icons {
  position: absolute;
  left: 50px;
  bottom: 150px;
}

.dt-offline-section > div.dt-offline-graphic > .dt-social-icons > li {
  text-align: center;
}

/*--------------------------------------------------------------
  ## Error page
  --------------------------------------------------------------*/
.dt-error-page-section {
  height: 100vh;
  padding: 60px;
  overflow: hidden;
}

.dt-error-page-section > .dt-error-container {
  height: 100%;
  border: 3px solid #000;
  position: relative;
}

.dt-error-page-section > .dt-error-container > img {
  width: 100%;
  height: auto;
  margin-top: -65px;
  margin-bottom: -65px;
  margin-left: -65px;
  transform: translateX(-25%);
}

.dt-error-page-section .dt-error-overlay {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  left: -65px;
  right: 40%;
  height: 100%;
  padding: 175px 150px 175px 50px;
}

.dt-error-page-section .dt-error-overlay > .content {
  background-color: #FFF;
  height: 100%;
  box-sizing: border-box;
  padding: 80px 60px 80px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
}

.dt-error-page-section .dt-error-overlay > .content > .info {
  width: 50%;
  padding-left: 45px;
}

.dt-error-page-section .dt-error-overlay > .content > .info > .title {
  color: #000;
}

.dt-error-page-section .dt-error-overlay > .content > .info > .subtitle {
  font-size: 1.6em;
}

.dt-error-page-section .dt-error-overlay > .content > .info > .dt-back {
  margin-top: 50px;
}

.dt-error-page-section .dt-error-overlay > .content > .info > .dt-back > a {
  font-size: 1.333333333333333em;
  text-decoration: underline;
}

.dt-error-page-section .dt-error-overlay > .content > img {
  width: 50%;
}

.dt-error-page-section .dt-error-social {
  position: absolute;
  right: 100px;
  top: 50%;
  transform: translateY(-50%);
}

.dt-error-page-section .dt-error-social > .title {
  font-weight: normal;
  text-transform: uppercase;
  color: #000;
  margin-bottom: 50px;
}

@media (max-width: 1024px) {
  .dt-error-page-section > .dt-error-container {
    overflow: hidden;
  }
  .dt-error-page-section > .dt-error-container > img {
    margin: 0;
    transform: none;
  }
  .dt-error-page-section .dt-error-overlay {
    left: 0;
    padding: 50px;
    right: 0;
  }
  .dt-error-page-section .dt-error-social {
    transform: none;
    top: auto;
    bottom: 100px;
    right: auto;
    left: 51.5%;
  }
  .dt-error-page-section .dt-error-social > .title {
    margin-bottom: 10px;
  }
  .dt-error-page-section .dt-error-social > ul {
    text-align: left;
  }
  .dt-error-page-section .dt-error-social > ul > li {
    display: inline-block;
    margin-right: 10px;
  }
}

@media (max-width: 768px) {
  .dt-error-page-section {
    height: auto;
    padding: 0;
  }
  .dt-error-page-section > .dt-error-container > img {
    height: 975px;
    width: auto;
  }
  .dt-error-page-section .dt-error-overlay > .content {
    display: block;
    padding: 50px;
    text-align: center;
  }
  .dt-error-page-section .dt-error-overlay > .content > img {
    width: 100%;
  }
  .dt-error-page-section .dt-error-overlay > .content > .info {
    width: 100%;
    padding: 0;
  }
  .dt-error-page-section .dt-error-social {
    width: 100%;
    left: 0;
    text-align: center;
  }
  .dt-error-page-section .dt-error-social > ul {
    text-align: center;
  }
}

@media (max-width: 480px) {
  .dt-error-page-section {
    padding: 0;
  }
  .dt-error-page-section > .dt-error-container > img {
    height: 750px;
  }
  .dt-error-page-section .dt-error-overlay {
    padding: 25px;
  }
}

/*--------------------------------------------------------------
  ## Coming Soon
  --------------------------------------------------------------*/
.dt-coming-soon-section {
  height: 100vh;
  padding: 60px;
  overflow: hidden;
}

.dt-coming-soon-section .dt-coming-soon-container {
  height: 100%;
  border: 3px solid #000;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dt-coming-soon-section .dt-coming-soon-container > .dt-coming-soon-image {
  float: left;
  margin-top: -65px;
  margin-bottom: -65px;
  margin-left: -65px;
}

.dt-coming-soon-section .dt-coming-soon-container > .dt-countdown-container {
  flex-basis: 100em;
  text-align: center;
  color: #000;
}

.dt-coming-soon-section .dt-coming-soon-container > .dt-countdown-container > .dt-title {
  font-size: 2.666666666666667em;
  margin-bottom: 50px;
}

@media (max-width: 1024px) {
  .dt-coming-soon-section .dt-coming-soon-container > .dt-coming-soon-image {
    width: 200%;
    overflow: hidden;
  }
  .dt-coming-soon-section .dt-coming-soon-container > .dt-coming-soon-image > img {
    height: 100%;
    width: auto;
  }
}

@media (max-width: 480px) {
  .dt-coming-soon-section .dt-coming-soon-container {
    display: block;
  }
  .dt-coming-soon-section .dt-coming-soon-container > .dt-coming-soon-image {
    display: none;
  }
  .dt-coming-soon-section .dt-countdown-timer {
    display: block;
  }
  .dt-coming-soon-section .dt-countdown-timer > div {
    margin-bottom: 25px;
  }
}

/*--------------------------------------------------------------
  # Widgets
  --------------------------------------------------------------*/
.widget {
  margin-bottom: 50px;
}

.widget:last-child {
  margin-bottom: 0;
}

.widget > .widget-title {
  margin-top: 0;
  margin-bottom: 35px;
  color: #000;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  padding-top: 12px;
  padding-bottom: 12px;
}

/*--------------------------------------------------------------
  ## Reviews
  --------------------------------------------------------------*/
.widget .dt-reviews-list > .dt-item {
  position: relative;
  padding-bottom: 15px;
  margin-bottom: 35px;
  border-bottom: 1px solid #cccccc;
}

.widget .dt-reviews-list > .dt-item:last-child {
  margin-bottom: 0;
  border-bottom: none;
}

.widget .dt-reviews-list > .dt-item > .dt-thumb {
  width: 96px;
  height: 128px;
  overflow: hidden;
  float: left;
}

.widget .dt-reviews-list > .dt-item > .dt-thumb > img {
  width: 100%;
  height: auto;
}

.widget .dt-reviews-list > .dt-item > .dt-content {
  padding-left: 116px;
}

.widget .dt-reviews-list > .dt-item > .dt-content > .dt-title {
  color: #000;
}

.widget .dt-reviews-list > .dt-item > .dt-content > .dt-ratting {
  color: #7e7e7e;
}

/*--------------------------------------------------------------
  ## Latest Comments
  --------------------------------------------------------------*/
.widget .dt-latest-comments > .dt-item {
  border-bottom: 1px solid #cccccc;
  margin-bottom: 30px;
}

.widget .dt-latest-comments > .dt-item:last-child {
  border-bottom: none;
  margin-bottom: 0;
}

.widget .dt-latest-comments > .dt-item > .dt-comment-info {
  margin-bottom: 20px;
}

.widget .dt-latest-comments > .dt-item > .dt-comment-info > .dt-avatar {
  width: 66px;
  height: 66px;
  float: left;
}

.widget .dt-latest-comments > .dt-item > .dt-comment-info > .dt-item-meta {
  padding-left: 86px;
}

.widget .dt-latest-comments > .dt-item > .dt-comment-info > .dt-item-meta > .dt-title {
  font-size: 1em;
  line-height: 1.333333333333333em;
  margin-top: 0;
  margin-bottom: 5px;
}

.widget .dt-latest-comments > .dt-item > .dt-comment-info > .dt-item-meta > .dt-date {
  font-size: 0.8888888888888889em;
  line-height: 1.2em;
  margin-bottom: 10px;
}

.widget .dt-latest-comments > .dt-item > .dt-comment-info > .dt-item-meta > .dt-on-post {
  font-size: 0.7777777777777778em;
  line-height: 1.2em;
  text-transform: uppercase;
}

.widget .dt-latest-comments > .dt-item > .dt-content {
  margin-bottom: 15px;
}

.widget .dt-latest-comments > .dt-item > .dt-item-action {
  margin-bottom: 20px;
  position: relative;
}

.widget .dt-latest-comments > .dt-item > .dt-item-action > .dt-comment-love {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 0.7777777777777778em;
}

.widget .dt-latest-comments > .dt-item > .dt-item-action > .dt-comment-love > a {
  color: #555555;
}

/*--------------------------------------------------------------
  ## Calendar
  --------------------------------------------------------------*/
.dt-projects-calendar {
  width: 100%;
}

.dt-projects-calendar > caption {
  position: relative;
  background-color: #000;
  color: #FFF;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  font-size: 0.7777777777777778em;
  padding: 0;
}

.dt-projects-calendar > caption > a {
  position: absolute;
  width: 30px;
  height: 100%;
  background-color: #FFF;
  border: 1px solid #000;
  top: 0;
}

.dt-projects-calendar > caption > a.prev-month {
  left: 0;
}

.dt-projects-calendar > caption > a.prev-month:before {
  content: "<";
  position: relative;
  top: -2px;
}

.dt-projects-calendar > caption > a.next-month {
  right: 0;
}

.dt-projects-calendar > caption > a.next-month:before {
  content: ">";
  position: relative;
  top: -2px;
}

.dt-projects-calendar > caption > a:hover {
  text-decoration: none;
}

.dt-projects-calendar thead {
  border-bottom: 1px solid #395687;
}

.dt-projects-calendar thead tr th {
  text-align: center;
  font-weight: normal;
  padding: 10px 0;
}

.dt-projects-calendar tbody > tr > td {
  text-align: center;
  padding: 4px 6px;
}

.dt-projects-calendar tbody > tr > td.today {
  font-weight: bold;
}

.dt-projects-calendar tbody > tr > td.old {
  opacity: 0.4;
}

.dt-projects-calendar tbody > tr > td > a {
  position: relative;
  display: block;
  overflow: hidden;
}

.dt-projects-calendar tbody > tr > td > a > .overlay-content {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.8);
  display: block;
  padding: 10px;
  width: 160px;
  left: -60px;
  color: #FFF;
  margin-top: 10px;
  transform: translateY(100%);
  opacity: 0;
  transition: all 0.35s cubic-bezier(0.18, 0.89, 0.32, 1.28);
}

.dt-projects-calendar tbody > tr > td > a > .overlay-content:before {
  content: '';
  position: absolute;
  top: -10px;
  left: 50%;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 10px 10px 10px;
  border-color: transparent transparent rgba(0, 0, 0, 0.8) transparent;
  transform: translateX(-50%);
}

.dt-projects-calendar tbody > tr > td > a:hover {
  color: #FFF;
  background-color: #333;
  text-decoration: none;
  overflow: visible;
}

.dt-projects-calendar tbody > tr > td > a:hover > .overlay-content {
  transform: translateY(0);
  opacity: 1;
}

.dt-projects-calendar tbody > tr:first-child > td {
  padding-top: 10px;
}

/*--------------------------------------------------------------
  ## WordPress Calendar
  --------------------------------------------------------------*/
#wp-calendar {
  width: 100%;
  position: relative;
}

#wp-calendar caption {
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
  color: #FFF;
  background-color: #000;
  padding: 0;
}

#wp-calendar thead tr {
  border-bottom: 1px solid #395687;
}

#wp-calendar thead tr th {
  text-align: center;
  color: #000;
  font-weight: normal;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

#wp-calendar tbody {
  color: #000;
}

#wp-calendar tbody td {
  text-align: center;
  padding: 8px;
}

#wp-calendar tbody .pad {
  background: none;
}

#wp-calendar tfoot #prev a,
#wp-calendar tfoot #next a {
  display: inline-block;
  text-transform: uppercase;
  font-size: 0.6em;
  font-weight: bold;
}

#wp-calendar tfoot #next {
  text-align: right;
}

/*--------------------------------------------------------------
  ## Popular Tags
  --------------------------------------------------------------*/
.widget .tagcloud > a {
  display: inline-block;
  margin-right: 15px;
  margin-bottom: 10px;
  font-size: 1em !important;
  line-height: 1.666666666666667em;
}

/*--------------------------------------------------------------
  ## About Us
  --------------------------------------------------------------*/
.widget_dt_about_us .dt-company-logo {
  width: 60px;
  float: left;
}

.widget_dt_about_us .dt-about-us-content {
  padding-left: 110px;
}

.widget_dt_about_us .list-2column {
  padding-left: 0;
  padding-top: 20px;
}

.widget_dt_about_us .list-2column > li {
  list-style: none;
  width: 50%;
  float: left;
  line-height: 1;
  margin-bottom: 25px;
}

.widget_dt_about_us .list-2column > li > a {
  text-transform: uppercase;
  font-size: 14px;
  color: #000;
}

/*--------------------------------------------------------------
  ## Links
  --------------------------------------------------------------*/
.widget_dt_links .dt-links {
  list-style: none;
  padding-left: 0;
}

.widget_dt_links .dt-links li {
  text-transform: uppercase;
  margin-bottom: 40px;
}

.widget_dt_links .dt-links li:last-child {
  margin-bottom: 0;
}

.widget_dt_links .dt-links li > a {
  color: #000;
}

/*--------------------------------------------------------------
  ## Latest Photos
  --------------------------------------------------------------*/
.widget .dt-latest-photos {
  margin-right: -10px;
  margin-left: -10px;
}

.widget .dt-latest-photos > .photo-items {
  position: relative;
}

.widget .dt-latest-photos > .photo-items > .dt-gallery-item {
  display: block;
  width: auto;
  height: 90px;
  overflow: hidden;
  margin: 10px;
}

.widget .dt-latest-photos > .photo-items > .dt-gallery-item > a {
  display: block;
  height: 100%;
}

.widget .dt-latest-photos > .photo-items > .dt-gallery-item > a > img {
  height: 100%;
  width: auto;
}

/*--------------------------------------------------------------
  ## Recent Tweets
  --------------------------------------------------------------*/
.widget .dt-recent-tweets > .dt-item {
  margin-bottom: 25px;
  padding-bottom: 25px;
  border-bottom: 1px solid #d5d8dc;
}

.widget .dt-recent-tweets > .dt-item:last-child {
  border-bottom: none;
}

.widget .dt-recent-tweets > .dt-item > span {
  display: block;
}

/*--------------------------------------------------------------
  ## Recent Photos
  --------------------------------------------------------------*/
.widget .dt-recent-posts > .dt-item {
  margin-bottom: 25px;
  padding-bottom: 25px;
  border-bottom: 1px solid #d5d8dc;
}

.widget .dt-recent-posts > .dt-item:last-child {
  border-bottom: none;
}

.widget .dt-recent-posts > .dt-item:before, .widget .dt-recent-posts > .dt-item:after {
  content: "";
  display: table;
}

.widget .dt-recent-posts > .dt-item:after {
  clear: both;
}

.widget .dt-recent-posts > .dt-item > .item-thumb {
  float: left;
  width: 80px;
  height: 50px;
  overflow: hidden;
}

.widget .dt-recent-posts > .dt-item > .item-thumb > img {
  width: 100%;
  height: auto;
}

.widget .dt-recent-posts > .dt-item > .item-content {
  padding-left: 90px;
}

.widget .dt-recent-posts > .dt-item > .item-content > .item-title {
  margin-top: 0;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  margin-bottom: 10px;
}

.widget .dt-recent-posts > .dt-item > .item-content > .item-date {
  font-size: 14px;
  color: #555555;
  line-height: 1;
}

.widget .dt-recent-posts > .dt-item > .item-content > .item-date > a {
  color: #555555;
  line-height: 1;
}

/*--------------------------------------------------------------
  # Media Queries
  --------------------------------------------------------------*/
@media (max-width: 768px) {
  .dt-blog-entries[data-column="2"] .dt-post {
    width: 100%;
  }
}
